// @ts-nocheck
const constraints = {
  firstName: {
    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  lastName: {
    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  description: {
    length: {
      minimum: 2,
      maximum: 50,
    },

    presence: {
      allowEmpty: true,
    },

    type: 'string',
  },

  emailAddress: {
    email: {
      message: '^E-mail address is invalid',
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  emailAddressConfirmation: {
    email: {
      message: '^E-mail address confirmation is invalid',
    },

    equality: {
      attribute: 'emailAddress',
      message: '^E-mail address confirmation is not equal to e-mail address',
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  password: {
    length: {
      minimum: 6,
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  passwordConfirmation: {
    equality: 'password',

    length: {
      minimum: 6,
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  roomName: {
    length: {
      minimum: 2,
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  bridgeDescription: {
    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  bridgeName: {
    length: {
      minimum: 2,
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  roomPassword: {
    presence: {
      allowEmpty: true,
    },

    type: 'string',
  },
  bridge: {
    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },
  forceTurn: {
    type: 'boolean',
  },
  allowAllStreams: {
    type: 'boolean',
  },
  allowAllNdiOut: {
    type: 'boolean',
  },
};

export default constraints;
