// @ts-nocheck
import { useState, useEffect } from 'react';

export function roundNumber(num, decimals) {
  const precision = Math.pow(10, decimals);
  return Math.round(num * precision) / precision;
}

export function useIntersection(element, options = {}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const elementRef = element.current;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(entry.isIntersecting);
        observer.observe(elementRef);
      }
    }, options);

    elementRef && observer.observe(elementRef);

    return () => observer.unobserve(elementRef);
  });

  return isVisible;
}

export function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}
