// @ts-nocheck
import camelCase from 'camelcase';
import { createTheme as muiCreateTheme } from '@mui/material/styles';
import { updateDoc, doc, deleteField } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage';

import { getOrgId } from './authentication';
import { analytics, auth, firestore, storage } from '../firebase';

import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey as gray,
  blueGrey as blueGray,
} from '@mui/material/colors';

export const colors = {
  red: {
    id: 'red',
    name: 'Red',
    import: red,
  },

  pink: {
    id: 'pink',
    name: 'Pink',
    import: pink,
  },

  purple: {
    id: 'purple',
    name: 'Purple',
    import: purple,
  },

  deepPurple: {
    id: 'deep-purple',
    name: 'Deep Purple',
    import: deepPurple,
  },

  indigo: {
    id: 'indigo',
    name: 'Indigo',
    import: indigo,
  },

  blue: {
    id: 'blue',
    name: 'Blue',
    import: blue,
  },

  lightBlue: {
    id: 'light-blue',
    name: 'Light Blue',
    import: lightBlue,
  },

  cyan: {
    id: 'cyan',
    name: 'Cyan',
    import: cyan,
  },

  teal: {
    id: 'teal',
    name: 'Teal',
    import: teal,
  },

  green: {
    id: 'green',
    name: 'Green',
    import: green,
  },

  lightGreen: {
    id: 'light-green',
    name: 'Light Green',
    import: lightGreen,
  },

  lime: {
    id: 'lime',
    name: 'Lime',
    import: lime,
  },

  yellow: {
    id: 'yellow',
    name: 'Yellow',
    import: yellow,
  },

  amber: {
    id: 'amber',
    name: 'Amber',
    import: amber,
  },

  orange: {
    id: 'orange',
    name: 'Orange',
    import: orange,
  },

  deepOrange: {
    id: 'deep-orange',
    name: 'Deep Orange',
    import: deepOrange,
  },

  brown: {
    id: 'brown',
    name: 'Brown',
    import: brown,
  },

  gray: {
    id: 'gray',
    name: 'Gray',
    import: gray,
  },

  blueGray: {
    id: 'blue-gray',
    name: 'Blue Gray',
    import: blueGray,
  },

  broadcasterRed: {
    id: 'broadcaster-red',
    name: 'Broadcaster Red',
    import: {
      main: 'rgb(255,71,100)',
    },
  },

  broadcasterBlack: {
    id: 'broadcaster-black',
    name: 'Broadcaster Black',
    import: {
      main: 'rgb(36, 40, 44)',
    },
  },
};

const getColor = (colorId) => {
  if (!colorId) {
    return null;
  }

  colorId = camelCase(colorId);

  return colors[colorId];
};

export const defaultPrimaryColor = getColor('broadcasterRed');
export const defaultSecondaryColor = getColor('broadcasterBlack');
export const defaultDark = process.env.VITE_THEME_DARK === 'true';

const defaultAppBarHeight = 64; //px
const highAppBarHeight = 135; //px

const defaultThemeOptions = {
  palette: {
    primary: defaultPrimaryColor.import,
    secondary: defaultSecondaryColor.import,
    mode: defaultDark ? 'dark' : 'light',
  },

  primaryColor: defaultPrimaryColor,
  secondaryColor: defaultSecondaryColor,
  dark: defaultDark,
};

export const defaultTheme = muiCreateTheme(defaultThemeOptions);

export const appBarHeight = {
  default: defaultAppBarHeight,
  high: highAppBarHeight,
};

export const isDefaultTheme = (theme) => {
  if (!theme) {
    return false;
  }

  if (
    theme.primaryColor.id === defaultPrimaryColor.id &&
    theme.secondaryColor.id === defaultSecondaryColor.id &&
    theme.dark === defaultDark
  ) {
    return true;
  }

  return false;
};

export const createTheme = (theme) => {
  if (!theme) {
    return null;
  }

  let primaryColor = theme.primaryColor;
  let secondaryColor = theme.secondaryColor;
  const dark = theme.dark;

  if (!primaryColor || !secondaryColor) {
    return null;
  }

  primaryColor = getColor(primaryColor);
  secondaryColor = getColor(secondaryColor);

  if (!primaryColor || !secondaryColor) {
    return null;
  }

  theme = muiCreateTheme({
    palette: {
      primary: primaryColor.import,
      secondary: secondaryColor.import,
      mode: dark ? 'dark' : 'light',
    },

    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    dark: dark,
  });

  return theme;
};

export const changeTheme = async (theme) => {
  if (!theme) {
    throw new Error('No theme');
  }

  let primaryColor = theme.primaryColor;
  let secondaryColor = theme.secondaryColor;
  const dark = theme.dark;

  if (!primaryColor || !secondaryColor) {
    throw new Error('No primary color or secondary color');
  }

  primaryColor = getColor(primaryColor);
  secondaryColor = getColor(secondaryColor);

  if (!primaryColor || !secondaryColor) {
    throw new Error('No primary color or secondary color');
  }

  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error('No current user');
  }

  try {
    const orgId = await getOrgId();
    if (!orgId) {
      throw new Error('No orgId');
    }

    const orgDocumentReference = doc(firestore, 'organisations', orgId);

    await updateDoc(orgDocumentReference, {
      theme: {
        primaryColor: primaryColor.id,
        secondaryColor: secondaryColor.id,
        dark: dark,
      },
    });

    logEvent(analytics, 'change_theme', {
      theme: theme,
    });
  } catch (reason) {
    throw reason;
  }
};

export const resetTheme = async () => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error('No current user');
  }
  try {
    const orgId = await getOrgId();

    if (!orgId) {
      throw new Error('No orgId');
    }

    const orgDocumentReference = doc(firestore, 'organisations', orgId);

    await updateDoc(orgDocumentReference, {
      theme: deleteField(),
    });
    logEvent(analytics, 'reset_theme');
  } catch (reason) {
    throw reason;
  }
};

export const changeLogo = (logo) => {
  return new Promise(async (resolve, reject) => {
    if (!logo) {
      reject(new Error('No logo'));

      return;
    }

    const logoFileTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/svg+xml',
    ];

    if (!logoFileTypes.includes(logo.type)) {
      reject(new Error('Invalid file type'));
      return;
    }

    if (logo.size > 20 * 1024 * 1024) {
      //TODO: insert proper size limitation
      reject(new Error('Invalid size'));
      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));
      return;
    }

    let orgId;
    try {
      orgId = await getOrgId();
      if (!orgId) {
        throw new Error('No orgId');
      }
    } catch (e) {
      reject(new Error('No orgId'));
      return;
    }

    const logoReference = ref(storage, `images/logos/${orgId}`);
    const orgDocumentReference = doc(firestore, 'organisations', orgId);

    uploadBytes(logoReference, logo)
      .then(() => {
        getDownloadURL(logoReference)
          .then((downloadURL) => {
            updateDoc(orgDocumentReference, {
              logoURL: downloadURL,
            })
              .then(() => {
                logEvent(analytics, 'change_logo');
                resolve(downloadURL);
              })
              .catch((reason) => {
                reject(reason);
              });
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const uploadBackground = (background) => {
  return new Promise(async (resolve, reject) => {
    if (!background) {
      reject(new Error('No background'));

      return;
    }

    const backgroundFileTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/svg+xml',
    ];

    if (!backgroundFileTypes.includes(background.type)) {
      reject(new Error('Invalid file type'));
      return;
    }

    if (background.size > 20 * 1024 * 1024) {
      //TODO: allow bigger size
      reject(new Error('Invalid size'));
      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));
      return;
    }

    let orgId;
    try {
      orgId = await getOrgId();
    } catch (e) {
      reject(new Error('No orgId'));
      return;
    }

    const backgroundReference = ref(storage, `images/backgrounds/${orgId}`);

    uploadBytes(backgroundReference, background)
      .then(() => {
        getDownloadURL(backgroundReference)
          .then((downloadURL) => {
            logEvent(analytics, 'change_background');
            resolve(downloadURL);
          })

          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const removeLogo = () => {
  return new Promise(async (resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    let orgId;
    try {
      orgId = await getOrgId();
    } catch (e) {
      reject(new Error('No orgId'));
      return;
    }

    const logoReference = ref(storage, `images/logos/${orgId}`);
    const orgDocumentReference = doc(firestore, 'organisations', orgId);

    deleteObject(logoReference)
      .then(() => {
        updateDoc(orgDocumentReference, {
          logoURL: deleteField(),
        })
          .then(() => {
            logEvent(analytics, 'delete_logo');
            resolve();
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const removeBackground = () => {
  return new Promise(async (resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    let orgId;
    try {
      orgId = await getOrgId();
    } catch (e) {
      reject(new Error('No orgId'));
      return;
    }

    const backgroundReference = ref(storage, `images/backgrounds/${orgId}`);

    try {
      await deleteObject(backgroundReference);

      const orgDocumentReference = doc(firestore, 'organisations', orgId);

      await updateDoc(orgDocumentReference, {
        background: deleteField(),
      });

      logEvent(analytics, 'delete_background');
      resolve();
    } catch (reason) {
      reject(reason);
    }
  });
};
