// @ts-nocheck
import React, { createContext } from 'react';

export const { Provider, Consumer } = createContext();

// eslint-disable-next-line react/display-name
export const withUserContext = (Component) => (props) =>
  <Consumer>{(value) => <Component {...value} {...props} />}</Consumer>;

const UserProvider = (props) => {
  const { user, userData } = props;

  return <Provider value={{ user, userData }}>{props.children}</Provider>;
};

export default UserProvider;
