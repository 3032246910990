// @ts-nocheck
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Button,
} from '@mui/material';

import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';

import { withSubscriptionContext } from '../../contexts/SubscriptionContext';

const initialState = {
  performingAction: false,
};

class EndTrialDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleKeyPress = (event) => {
    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      this.handleEndActiveTrial();
    }
  };

  handleExited = () => {
    this.setState(initialState);
  };

  endActiveTrial = async () => {
    const { orgSubscription, dialogProps, openSnackbar } = this.props;

    const getDefaultPaymentMethodForSub = httpsCallable(
      functions,
      'stripe-getDefaultPaymentMethodForSub',
    );

    const defaultPaymentMethodCall = await getDefaultPaymentMethodForSub({
      subId: orgSubscription.id,
    });

    const defaultPaymentMethod =
      defaultPaymentMethodCall && defaultPaymentMethodCall.data;

    if (!defaultPaymentMethod) {
      openSnackbar(
        'Error terminating trial: Missing payment method. To add a payment method and start your subscription, please go to Menu > Manage subscription',
      );
      dialogProps.onClose();
      return;
    }

    const endTrialNow = httpsCallable(functions, 'stripe-endTrialNow');
    if (!orgSubscription || orgSubscription.status !== 'trialing') {
      openSnackbar('There are no active trials!');
      dialogProps.onClose();
      return;
    }

    try {
      const res = await endTrialNow({ subscriptionId: orgSubscription.id });
      if (res) {
        openSnackbar('Trial ended successfully.');
        dialogProps.onClose();
      }
    } catch (reason) {
      openSnackbar(`Error terminating trial: ${reason.message}`);
    }
  };

  handleEndActiveTrial = () => {
    const { orgSubscription, openSnackbar } = this.props;
    const { stripeCustomerId } = orgSubscription;

    if (!stripeCustomerId || !orgSubscription.id) {
      openSnackbar('Error: cannot retrieve subscription information');
      return;
    }

    this.setState(
      {
        performingAction: true,
      },
      async () => {
        this.endActiveTrial();
      },
      () => {
        this.setState({ performingAction: false });
      },
    );
  };

  render() {
    const { dialogProps } = this.props;
    const { performingAction } = this.state;

    return (
      <Dialog
        {...dialogProps}
        onKeyPress={this.handleKeyPress}
        TransitionProps={{
          onExited: this.handleExited,
        }}
      >
        <DialogTitle>End trial?</DialogTitle>

        <DialogContent>
          <Box>
            <DialogContentText>
              You can end your trial now to remove watermarks. Billing for your
              subscription will start today. <br />
              Please make sure you have provided a valid payment method before
              you proceed. <br />
              <br />
              This action cannot be undone.
            </DialogContentText>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color='secondary'
            disabled={performingAction}
            onClick={dialogProps.onClose}
          >
            Cancel
          </Button>
          <Button
            color='error'
            disabled={performingAction}
            variant='contained'
            onClick={this.handleEndActiveTrial}
          >
            End trial
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EndTrialDialog.propTypes = {
  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Properties
  performingAction: PropTypes.bool.isRequired,
  userData: PropTypes.object,
};

export default withSubscriptionContext(EndTrialDialog);
