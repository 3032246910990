// @ts-nocheck
export const appReducer = (state, action) => {
  // console.log('running a reducer', state, action);
  switch (action.type) {
    case 'UPDATE_CHOSEN_MIC':
      return { ...state, ...{ chosenAudioInput: action.deviceId } };
    case 'UPDATE_CHOSEN_CAMERA':
      return { ...state, ...{ chosenVideoInput: action.deviceId } };
    case 'UPDATE_CHOSEN_OUTPUT':
      return { ...state, ...{ chosenAudioOutput: action.deviceId } };
    default:
      return state;
  }
};
