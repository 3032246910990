// @ts-nocheck
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import { Avatar, ListItemAvatar } from '@mui/material';

import {
  AccountCircle as AccountCircleIcon,
  Person as PersonIcon,
} from '@mui/icons-material';

import { getNameInitials } from '../../services/authentication';
import { withUserContext } from '../../contexts/UserContext';

const styles = (theme) => ({
  nameInitials: {
    cursor: 'default',
  },
});

class UserAvatar extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { context, user, userData, defaultCursor } = this.props;

    if (context === 'standalone') {
      if (!user) {
        return <AccountCircleIcon />;
      }

      const photoUrl = user.photoURL;

      if (photoUrl) {
        return <Avatar alt='Avatar' src={photoUrl} />;
      }

      const nameInitials = getNameInitials({
        ...user,
        ...userData,
      });

      if (nameInitials) {
        return (
          <Avatar alt='Avatar'>
            <span className={defaultCursor && classes.nameInitials}>
              {nameInitials}
            </span>
          </Avatar>
        );
      }

      return <AccountCircleIcon />;
    }

    if (context === 'list') {
      if (!user) {
        return (
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
        );
      }

      const photoUrl = user.photoURL;

      if (photoUrl) {
        return (
          <ListItemAvatar>
            <Avatar alt='Avatar' src={photoUrl} />
          </ListItemAvatar>
        );
      }

      const nameInitials = getNameInitials({
        ...user,
        ...userData,
      });

      if (nameInitials) {
        return (
          <ListItemAvatar>
            <Avatar alt='Avatar'>
              <span className={defaultCursor && classes.nameInitials}>
                {nameInitials}
              </span>
            </Avatar>
          </ListItemAvatar>
        );
      }

      return (
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
      );
    }

    return null;
  }
}

UserAvatar.defaultProps = {
  context: 'standalone',
};

UserAvatar.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  context: PropTypes.string,
  user: PropTypes.object.isRequired,
  defaultCursor: PropTypes.bool,
};

export default withStyles(styles)(withUserContext(UserAvatar));
