// @ts-nocheck
export const videoCodecsAvailable = {
  micro: ['vp8', 'vp9', 'h264'],
  mega: ['vp8', 'vp9', 'h264', 'av1'],
};

export const maxParticipantsLimit = {
  nano: 2,
  micro: 10,
  mega: 20,
  unsubscribed: 1,
};

export const videoResLimit = {
  nano: '1280x720',
  micro: '1920x1080',
  mega: '3840x2160',
};

export const roomsLimit = {
  nano: 3,
  micro: 20,
  mega: 50,
  unsubscribed: 1,
};

export const bridgeLimit = {
  nano: 2,
  micro: 10,
  mega: 100,
  unsubscribed: 1,
};

export const bandwidthAllowance = {
  nano: 30,
  micro: 80,
  mega: 200,
  trial: 0.5,
  unsubscribed: 0.001,
};

export const frameRateLimit = {
  nano: 30,
};
