// @ts-nocheck
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import {
  DialogContent,
  Box,
  List,
  ListItem,
  ListItemIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
} from '@mui/material';

import {
  SettingsVoice as SettingsVoiceIcon,
  Videocam as VideocamIcon,
} from '@mui/icons-material';

import { withContext } from '../../contexts/AppContext';
import { hasOwnProperty } from '../../lib/utils';

class DevicesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      performingAction: false,
      devices: {
        audioinput: new Map(),
        audiooutput: new Map(),
        videoinput: new Map(),
      },
      // speakerDeviceLabelWidth: 0,
    };

    this.micDeviceLabel = React.createRef();
    this.cameraDeviceLabel = React.createRef();
    this.speakerDeiceLabel = React.createRef();
  }

  handleAudioOutputDeviceChange = (event) => {
    const { dispatch } = this.props;

    if (!event) {
      return;
    }

    const audioOutputDeviceId = event.target.value;

    this.setState(
      {
        performingAction: true,
      },
      () => {
        this.setState({ performingAction: false });
        dispatch({
          type: 'UPDATE_CHOSEN_OUTPUT',
          deviceId: audioOutputDeviceId,
        });
      },
    );
  };

  handleAudioDeviceChange = (event) => {
    const { dispatch } = this.props;

    if (!event) {
      return;
    }

    const audioDeviceId = event.target.value;

    this.setState(
      {
        performingAction: true,
      },
      () => {
        dispatch({ type: 'UPDATE_CHOSEN_MIC', deviceId: audioDeviceId });
        this.setState({ performingAction: false });
      },
    );
  };

  handleVideoDeviceChange = (event) => {
    const { dispatch } = this.props;

    if (!event) {
      return;
    }

    const videoDeviceId = event.target.value;

    this.setState(
      {
        performingAction: true,
      },
      () => {
        dispatch({ type: 'UPDATE_CHOSEN_CAMERA', deviceId: videoDeviceId });
        this.setState({ performingAction: false });
      },
    );
  };

  render() {
    // Properties
    const { chosenAudioInput, chosenVideoInput } = this.props;

    const { performingAction, devices } = this.state;

    const audioInputs = Object.fromEntries(devices.audioinput);
    const videoInputs = Object.fromEntries(devices.videoinput);

    return (
      <DialogContent>
        <List disablePadding>
          <Box mb={1}>
            <ListItem>
              <Hidden smDown>
                <ListItemIcon sx={{ color: 'primary.main' }}>
                  <SettingsVoiceIcon />
                </ListItemIcon>
              </Hidden>

              <FormControl
                disabled={performingAction}
                fullWidth
                variant='outlined'
              >
                <InputLabel ref={this.micDeviceLabel}>
                  Default Microphone
                </InputLabel>

                <Hidden smUp>
                  <Select
                    native
                    value={
                      hasOwnProperty(audioInputs, chosenAudioInput)
                        ? chosenAudioInput
                        : ''
                    }
                    label='Default Microphone'
                    onChange={this.handleAudioDeviceChange}
                  >
                    {Object.keys(audioInputs).map((deviceId) => {
                      return (
                        <option key={deviceId} value={deviceId}>
                          {audioInputs[deviceId]}
                        </option>
                      );
                    })}
                  </Select>
                </Hidden>

                <Hidden smDown>
                  <Select
                    value={
                      hasOwnProperty(audioInputs, chosenAudioInput)
                        ? chosenAudioInput
                        : ''
                    }
                    label='Default Microphone'
                    onChange={this.handleAudioDeviceChange}
                  >
                    {Object.keys(audioInputs).map((deviceId) => {
                      return (
                        <MenuItem key={deviceId} value={deviceId}>
                          {audioInputs[deviceId]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Hidden>
              </FormControl>
            </ListItem>
          </Box>

          <Box mb={1}>
            <ListItem>
              <Hidden smDown>
                <ListItemIcon sx={{ color: 'primary.main' }}>
                  <VideocamIcon />
                </ListItemIcon>
              </Hidden>

              <FormControl
                disabled={performingAction}
                fullWidth
                variant='outlined'
              >
                <InputLabel ref={this.cameraDeviceLabel}>
                  Default Video Camera
                </InputLabel>

                <Hidden smUp>
                  <Select
                    native
                    value={
                      hasOwnProperty(videoInputs, chosenVideoInput)
                        ? chosenVideoInput
                        : ''
                    }
                    label='Default Video Camera'
                    onChange={this.handleVideoDeviceChange}
                  >
                    {Object.keys(videoInputs).map((deviceId) => {
                      return (
                        <option key={deviceId} value={deviceId}>
                          {videoInputs[deviceId]}
                        </option>
                      );
                    })}
                  </Select>
                </Hidden>

                <Hidden smDown>
                  <Select
                    value={
                      hasOwnProperty(videoInputs, chosenVideoInput)
                        ? chosenVideoInput
                        : ''
                    }
                    label='Default Video Camera'
                    onChange={this.handleVideoDeviceChange}
                  >
                    {Object.keys(videoInputs).map((deviceId) => {
                      return (
                        <MenuItem key={deviceId} value={deviceId}>
                          {videoInputs[deviceId]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Hidden>
              </FormControl>
            </ListItem>
          </Box>

          {/* <Box mb={1}>
            <ListItem>
              <Hidden xsDown>
                <ListItemIcon>
                  <SpeakerIcon color="primary" />
                </ListItemIcon>
              </Hidden>

              <FormControl
                disabled={performingAction}
                fullWidth
                variant="outlined"
              >
                <InputLabel ref={this.cameraDeviceLabel}>
                  Output Device
                </InputLabel>

                <Hidden smUp>
                  <Select
                    native
                    value={audioOutputs.hasOwnProperty(chosenAudioOutput) ? chosenAudioOutput : ''}
                    labelWidth={speakerDeviceLabelWidth}
                    onChange={this.handleAudioOutputDeviceChange}
                  >
                    {Object.keys(audioOutputs).map((deviceId) => {
                      return (
                        <option key={deviceId} value={deviceId}>
                          {audioOutputs[deviceId]}
                        </option>
                      );
                    })}
                  </Select>
                </Hidden>

                <Hidden xsDown>
                  <Select
                    value={audioOutputs.hasOwnProperty(chosenAudioOutput) ? chosenAudioOutput : ''}
                    labelWidth={speakerDeviceLabelWidth}
                    onChange={this.handleAudioOutputDeviceChange}
                  >
                    {Object.keys(audioOutputs).map((deviceId) => {
                      return (
                        <MenuItem key={deviceId} value={deviceId}>
                          {audioOutputs[deviceId]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Hidden>
              </FormControl>
            </ListItem>
          </Box> */}
        </List>
      </DialogContent>
    );
  }

  async getDevices() {
    const tmpDevices = await navigator.mediaDevices.enumerateDevices();

    const devices = {
      videoinput: new Map(),
      audioinput: new Map(),
      audiooutput: new Map(),
    };

    tmpDevices.forEach((device) => {
      devices[device.kind].set(device.deviceId, device.label);
    });

    this.setState({
      devices,
    });
  }

  componentDidMount() {
    this.getDevices();

    navigator.mediaDevices.ondevicechange = (event) => {
      this.getDevices();
    };
  }
}

DevicesTab.propTypes = {
  // Properties

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withContext(DevicesTab);
