// @ts-nocheck
import { analytics, firestore } from '../firebase';
import {
  collection,
  getDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  addDoc,
} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { getCurrentUser, getOrgId } from './authentication';

export const create = async (fields) => {
  if (!fields) {
    throw new Error('No fields');
  }

  const name = fields.bridgeName;
  const description = fields.description;

  if (!name) {
    throw new Error('No Bridge name');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const orgBridgeCollectionRef = collection(
    firestore,
    `organisations/${userOrgId}/bridges`,
  );

  try {
    await addDoc(orgBridgeCollectionRef, {
      name,
      description,
      createdByUserId: currentUser.uid,
      atemSwitcher: [],
    });
    logEvent(analytics, 'create_bridge');
  } catch (reason) {
    throw reason;
  }
};

export const updateConnectedAccessoryData = async (
  id,
  connectedAccessoryData,
) => {
  if (!id) {
    throw new Error('No Bridge ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const bridgeRef = doc(firestore, `organisations/${userOrgId}/bridges`, id);

  try {
    await updateDoc(bridgeRef, {
      connectedAccessoryData: JSON.stringify(connectedAccessoryData),
    });
    logEvent(analytics, 'change_bridge_connected_accessory_data');
  } catch (reason) {
    throw reason;
  }
};

export const updateInputOutput = async (path, data) => {
  const newRef = doc(firestore, path);

  try {
    await updateDoc(newRef, data);
    //logEvent(analytics, 'change_bridge_description');
  } catch (reason) {
    throw reason;
  }
};

export const getConnectedAccessoryData = async (id) => {
  if (!id) {
    throw new Error('No Bridge ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const bridgeRef = doc(firestore, `organisations/${userOrgId}/bridges`, id);

  let connectedAccessoryData = {};
  try {
    const data = await getDoc(bridgeRef);
    const accessoryData = data.data()?.connectedAccessoryData;
    if (!accessoryData) {
      return null;
    }
    connectedAccessoryData = JSON.parse(accessoryData);
    logEvent(analytics, 'get_bridge_connected_accessory_data');
  } catch (reason) {
    throw reason;
  }
  return connectedAccessoryData;
};

export const createConnectedAccessory = async (id, fields) => {
  if (!fields) {
    throw new Error('No fields');
  }

  const name = fields.name;

  if (!name) {
    throw new Error('No Bridge Accessory name');
  }

  if (!id) {
    throw new Error('No Bridge ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const orgBridgeCollectionRef = collection(
    firestore,
    `organisations/${userOrgId}/bridges/${id}/connectedAccessories`,
  );

  try {
    await addDoc(orgBridgeCollectionRef, {
      name,
      createdByUserId: currentUser.uid,
      isAccessory: true,
    });
    logEvent(analytics, 'create_bridge_accessory');
  } catch (reason) {
    throw reason;
  }
};

export const deleteConnectedAccessory = async (bridgeId, accessoryId) => {
  if (!bridgeId) {
    throw new Error('No Bridge ID');
  }

  if (!accessoryId) {
    throw new Error('No Accessory ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const orgBridgeCollectionRef = doc(
    firestore,
    `organisations/${userOrgId}/bridges/${bridgeId}/connectedAccessories/${accessoryId}`,
  );

  try {
    await deleteDoc(orgBridgeCollectionRef);
    logEvent(analytics, 'delete_bridge_accessory');
  } catch (reason) {
    throw reason;
  }
};

export const updateConnectedAccessory = async (
  bridgeId,
  accessoryId,
  fields,
) => {
  if (!fields) {
    throw new Error('No Accessory fields');
  }

  if (!bridgeId) {
    throw new Error('No Bridge ID');
  }

  if (!accessoryId) {
    throw new Error('No Accessory ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const orgBridgeCollectionRef = doc(
    firestore,
    `organisations/${userOrgId}/bridges/${bridgeId}/connectedAccessories/${accessoryId}`,
  );

  try {
    await updateDoc(orgBridgeCollectionRef, { ...fields });
    logEvent(analytics, 'change_bridge_accessory_fields');
  } catch (reason) {
    throw reason;
  }
};

export const changeDescription = async (id, description) => {
  if (!description) {
    throw new Error('No Bridge description');
  }

  if (!id) {
    throw new Error('No Bridge ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const bridgeRef = doc(firestore, `organisations/${userOrgId}/bridges`, id);

  try {
    await updateDoc(bridgeRef, { description });
    logEvent(analytics, 'change_bridge_description');
  } catch (reason) {
    throw reason;
  }
};

export const changeAtemSwitcher = async (id, atemSwitcher) => {
  if (!atemSwitcher) {
    throw new Error('No Bridge atemSwitcher');
  }

  if (!id) {
    throw new Error('No Bridge ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const bridgeRef = doc(firestore, `organisations/${userOrgId}/bridges`, id);

  try {
    await updateDoc(bridgeRef, { atemSwitcher });
    logEvent(analytics, 'change_bridge_atem_switcher');
  } catch (reason) {
    throw reason;
  }
};

export const deleteBridge = async (id) => {
  if (!id) {
    throw new Error('No Bridge ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  try {
    await deleteDoc(doc(firestore, `organisations/${userOrgId}/bridges`, id));
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const list = async () => {
  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  try {
    const array = [];

    const snapshot = await getDocs(
      collection(firestore, `organisations/${userOrgId}/bridges`),
    );
    snapshot.forEach((doc) => {
      array.push({ ...doc.data(), id: doc.id });
    });
    logEvent(analytics, 'listed_bridges');
    return array;
  } catch (err) {
    throw err;
  }
};

export const getBridgeCollection = async (orgId, bridgeId) => {
  if (!orgId || !bridgeId) {
    throw new Error('no Org ID or Bridge ID');
  }

  return 'bridges';
};
