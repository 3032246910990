// @ts-nocheck
import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  BrowserRouter,
  Switch,
  Redirect,
  Route,
  matchPath,
} from 'react-router-dom';
import * as logger from 'loglevel';

import { withSubscriptionContext } from '../../contexts/SubscriptionContext';
import { withUserContext } from '../../contexts/UserContext';

import Footer from '../Footer';
// import GettingStarted from '../GettingStarted';

const HomePage = lazy(() => import('../HomePage'));
// const AdminPage = lazy(() => import('../AdminPage'));
const UserPage = lazy(() => import('../UserPage'));
const LiveSessionPage = lazy(() => import('../LiveSessionPage'));
const NotFoundPage = lazy(() => import('../NotFoundPage'));
const NotAllowedPage = lazy(() => import('../NotAllowedPage'));
const RoomsList = lazy(() => import('../RoomsList'));
const EditRoomPage = lazy(() => import('../EditRoomPage'));
const BridgeList = lazy(() => import('../BridgeList'));
const EditBridgePage = lazy(() => import('../EditBridgePage'));
const GettingStarted = lazy(() => import('../GettingStarted'));
const LocalConnector = lazy(() => import('../LocalConnector'));
const PrivacyPolicy = lazy(() => import('../PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('../TermsAndConditions'));
const Packages = lazy(() => import('../Packages'));
const CustomerPortal = lazy(() => import('../CustomerPortal'));
const SupportPage = lazy(() => import('../SupportPage'));

logger.setLevel('silent');

class DebugRouter extends BrowserRouter {
  constructor(props) {
    super(props);
    logger.debug('initial history is ', this.history);
    this.history.listen((location, action) => {
      logger.debug(
        `The current URL is ${location.pathname}${location.search}${location.hash}`,
      );
      logger.debug(`The last navigation action was ${action}`, this.history);
    });
  }
}

const styles = (theme) => ({
  container: {
    flex: '1 0 auto',
  },
});

class Router extends Component {
  render() {
    // Properties
    const { user, bar } = this.props;

    // Functions
    const {
      openSnackbar,
      onCreateRoomClick,
      onCreateBridgeClick,
      onSignUpClick,
      classes,
      isInIFrame,
      setTheme,
      setLogo,
      signOut,
      orgSubscription,
      onEndTrialClick,
    } = this.props;

    // We redirect to /pricing
    // users that don't have a subscription, or it's cancelled.
    // If it's or overdue, we redirect to the Customer Portal.
    const hasSubscription = ['active', 'trialing'].includes(
      orgSubscription?.status,
    );

    return (
      <DebugRouter>
        {!isInIFrame && bar}

        <div className={classes.container}>
          <Suspense fallback=''>
            <Switch>
              <Route path='/' exact>
                {isInIFrame ? (
                  <NotAllowedPage />
                ) : user && user.orgId ? (
                  hasSubscription ? (
                    <Redirect to='/getting-started' />
                  ) : (
                    <Redirect to='/pricing' />
                  )
                ) : (
                  <HomePage
                    openSnackbar={openSnackbar}
                    onSignUpClick={onSignUpClick}
                  />
                )}
              </Route>

              <Route path='/pricing' exact>
                {!orgSubscription ? (
                  <Packages
                    onSignUpClick={onSignUpClick}
                    openSnackbar={openSnackbar}
                  />
                ) : orgSubscription?.status === 'past_due' ? (
                  <Redirect to='/manage-subscription' />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route path='/getting-started' exact>
                {!isInIFrame && user && user.orgId && hasSubscription ? (
                  <GettingStarted />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route path='/running-the-bridge' exact>
                {!isInIFrame && user && user.orgId && hasSubscription ? (
                  <LocalConnector openSnackbar={openSnackbar} />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route path='/rooms' exact>
                {!isInIFrame && user && user.orgId && hasSubscription ? (
                  <RoomsList
                    onCreateRoomClick={onCreateRoomClick}
                    openSnackbar={openSnackbar}
                  />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route path='/rooms/:roomId' exact>
                {!isInIFrame && user && user.orgId && hasSubscription ? (
                  <EditRoomPage openSnackbar={openSnackbar} />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route path='/bridges' exact>
                {!isInIFrame && user && user.orgId && hasSubscription ? (
                  <BridgeList
                    onCreateBridgeClick={onCreateBridgeClick}
                    openSnackbar={openSnackbar}
                  />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route path='/bridges/:bridgeId' exact>
                {!isInIFrame && user && user.orgId && hasSubscription ? (
                  <EditBridgePage openSnackbar={openSnackbar} />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route
                path='/ext/:orgId/:roomId'
                exact
                render={({ match }) => {
                  if (isInIFrame) {
                    return (
                      <Redirect
                        to={`/embed/${match.params.orgId}/${match.params.roomId}`}
                      />
                    );
                  }
                  if (user && user.orgId === match.params.orgId) {
                    return <Redirect to={`/session/${match.params.roomId}`} />;
                  } else {
                    return (
                      <LiveSessionPage
                        openSnackbar={openSnackbar}
                        setTheme={setTheme}
                        setLogo={setLogo}
                        signOut={signOut}
                      />
                    );
                  }
                }}
              />

              <Route
                path='/ext/:orgId/:roomId/:inviteId'
                exact
                render={({ match }) => {
                  if (isInIFrame) {
                    return (
                      <Redirect
                        to={`/embed/${match.params.orgId}/${match.params.roomId}`}
                      />
                    );
                  }
                  if (user && user.orgId === match.params.orgId) {
                    return <Redirect to={`/session/${match.params.roomId}`} />;
                  } else {
                    return (
                      <LiveSessionPage
                        openSnackbar={openSnackbar}
                        setTheme={setTheme}
                        setLogo={setLogo}
                        signOut={signOut}
                        inviteId={match.params.inviteId}
                      />
                    );
                  }
                }}
              />

              <Route
                path='/embed/:orgId/:roomId'
                exact
                render={({ match }) => {
                  if (!isInIFrame) {
                    return (
                      <Redirect
                        to={`/ext/${match.params.orgId}/${match.params.roomId}`}
                      />
                    );
                  } else {
                    return (
                      <LiveSessionPage
                        openSnackbar={openSnackbar}
                        setTheme={setTheme}
                        setLogo={setLogo}
                        signOut={signOut}
                        isInIFrame={isInIFrame}
                      />
                    );
                  }
                }}
              />

              <Route path='/session/websource/:orgId/:roomId/:sourceId' exact>
                <LiveSessionPage
                  websource={true}
                  openSnackbar={openSnackbar}
                  setTheme={setTheme}
                  setLogo={setLogo}
                  signOut={signOut}
                  isInIFrame={isInIFrame}
                />
              </Route>

              <Route path='/session/:roomId' exact>
                {!isInIFrame && user && user.orgId && hasSubscription ? (
                  <LiveSessionPage
                    openSnackbar={openSnackbar}
                    setTheme={setTheme}
                    setLogo={setLogo}
                    signOut={signOut}
                  />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              {/* <Route path='/admin'>
                {!isInIFrame && user && roles.includes('admin') ? (
                  <AdminPage />
                ) : (
                  <Redirect to='/' />
                )}
              </Route> */}

              <Route path='/user/:userId'>
                {!isInIFrame && user && user.orgId ? (
                  <UserPage />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route path='/manage-subscription' exact>
                {!isInIFrame && user && user.orgId && orgSubscription ? (
                  <CustomerPortal
                    openSnackbar={openSnackbar}
                    onEndTrialClick={onEndTrialClick}
                  />
                ) : (
                  <Redirect to='/' />
                )}
              </Route>

              <Route path='/support' exact>
                {!isInIFrame ? <SupportPage /> : <Redirect to='/' />}
              </Route>

              <Route path='/terms-and-conditions'>
                <TermsAndConditions />
              </Route>

              <Route path='/privacy-policy'>
                <PrivacyPolicy />
              </Route>

              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
          </Suspense>
        </div>
        <Footer isInIFrame={isInIFrame} />
      </DebugRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withSubscriptionContext(withUserContext(Router)),
);
