// @ts-nocheck
import React, { createContext } from 'react';

export const { Provider, Consumer } = createContext();

// eslint-disable-next-line react/display-name
export const withSubscriptionContext = (Component) => (props) =>
  <Consumer>{(value) => <Component {...value} {...props} />}</Consumer>;

const SubscriptionProvider = (props) => {
  const { orgSubscription } = props;

  return (
    <Provider value={{ orgSubscription: orgSubscription }}>
      {props.children}
    </Provider>
  );
};

export default SubscriptionProvider;
