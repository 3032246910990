// @ts-nocheck
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Hidden } from '@mui/material';

import AboutDialog from '../AboutDialog';

import SignUpDialog from '../SignUpDialog';
import SignInDialog from '../SignInDialog';
import SettingsDialog from '../SettingsDialog';
import DeleteAccountDialog from '../DeleteAccountDialog';
import AlertDialog from '../AlertDialog';
import CreateRoomDialog from '../CreateRoomDialog';
import CreateBridgeDialog from '../CreateBridgeDialog';
import OrganisationSignupDialog from '../OrganisationSignupDialog';
import EndTrialDialog from '../EndTrialDialog';

import { withUserContext } from '../../contexts/UserContext';

class DialogHost extends Component {
  render() {
    // Properties
    const { performingAction, theme, user, userData, dialogs, logoURL } =
      this.props;

    // Functions
    const { openSnackbar, setLogo } = this.props;

    const aboutDialog = dialogs.aboutDialog;
    const signUpDialog = dialogs.signUpDialog;
    const signInDialog = dialogs.signInDialog;
    const settingsDialog = dialogs.settingsDialog;
    const deleteAccountDialog = dialogs.deleteAccountDialog;
    const signOutDialog = dialogs.signOutDialog;
    const createRoomDialog = dialogs.createRoomDialog;
    const createBridgeDialog = dialogs.createBridgeDialog;
    const organisationSignupDialog = dialogs.organisationSignupDialog;
    const endTrialDialog = dialogs.endTrialDialog;

    const isLoggedIn = user && !user.isAnonymous;

    return (
      <>
        <AboutDialog
          dialogProps={aboutDialog.dialogProps}
          performingAction={performingAction}
          theme={theme}
          user={user}
          userData={userData}
          openSnackbar={openSnackbar}
          {...aboutDialog.props}
        />

        {isLoggedIn && (
          <>
            <AlertDialog
              dialogProps={signOutDialog.dialogProps}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
              {...signOutDialog.props}
            />

            <CreateRoomDialog
              dialogProps={createRoomDialog.dialogProps}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
              {...createRoomDialog.props}
            />

            <CreateBridgeDialog
              dialogProps={createBridgeDialog.dialogProps}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
              {...createBridgeDialog.props}
            />
          </>
        )}

        <Hidden smDown>
          {isLoggedIn && (
            <>
              <DeleteAccountDialog
                dialogProps={deleteAccountDialog.dialogProps}
                performingAction={performingAction}
                theme={theme}
                user={user}
                userData={userData}
                openSnackbar={openSnackbar}
                {...deleteAccountDialog.props}
              />

              <EndTrialDialog
                dialogProps={endTrialDialog.dialogProps}
                performingAction={performingAction}
                theme={theme}
                user={user}
                userData={userData}
                openSnackbar={openSnackbar}
                {...endTrialDialog.props}
              />
            </>
          )}

          {!isLoggedIn && (
            <>
              <SignUpDialog
                dialogProps={signUpDialog.dialogProps}
                performingAction={performingAction}
                theme={theme}
                user={user}
                userData={userData}
                openSnackbar={openSnackbar}
                {...signUpDialog.props}
              />

              <SignInDialog
                dialogProps={signInDialog.dialogProps}
                performingAction={performingAction}
                theme={theme}
                user={user}
                userData={userData}
                openSnackbar={openSnackbar}
                {...signInDialog.props}
              />
            </>
          )}

          {isLoggedIn && !user.orgId && (
            <OrganisationSignupDialog
              dialogProps={organisationSignupDialog.dialogProps}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
              {...organisationSignupDialog.props}
            />
          )}
        </Hidden>

        <Hidden mdDown>
          <>
            <SettingsDialog
              dialogProps={settingsDialog.dialogProps}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
              logoURL={logoURL}
              setLogo={setLogo}
              {...settingsDialog.props}
            />
          </>
        </Hidden>

        <Hidden smUp>
          {isLoggedIn && (
            <>
              <DeleteAccountDialog
                dialogProps={{
                  fullScreen: true,

                  ...deleteAccountDialog.dialogProps,
                }}
                performingAction={performingAction}
                theme={theme}
                user={user}
                userData={userData}
                openSnackbar={openSnackbar}
                {...deleteAccountDialog.props}
              />

              <EndTrialDialog
                dialogProps={{
                  fullScreen: true,
                  ...endTrialDialog.dialogProps,
                }}
                performingAction={performingAction}
                theme={theme}
                user={user}
                userData={userData}
                openSnackbar={openSnackbar}
                {...endTrialDialog.props}
              />
            </>
          )}

          {!isLoggedIn && (
            <>
              <SignUpDialog
                dialogProps={{
                  fullScreen: true,

                  ...signUpDialog.dialogProps,
                }}
                performingAction={performingAction}
                theme={theme}
                user={user}
                userData={userData}
                openSnackbar={openSnackbar}
                {...signUpDialog.props}
              />

              <SignInDialog
                dialogProps={{
                  fullScreen: true,

                  ...signInDialog.dialogProps,
                }}
                performingAction={performingAction}
                theme={theme}
                user={user}
                userData={userData}
                openSnackbar={openSnackbar}
                {...signInDialog.props}
              />
            </>
          )}

          {isLoggedIn && !user.orgId && (
            <OrganisationSignupDialog
              dialogProps={{
                fullScreen: true,

                ...organisationSignupDialog.dialogProps,
              }}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
              {...organisationSignupDialog.props}
            />
          )}
        </Hidden>

        <Hidden mdUp>
          <>
            <SettingsDialog
              dialogProps={{
                fullScreen: true,

                ...settingsDialog.dialogProps,
              }}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
              logoURL={logoURL}
              setLogo={setLogo}
              {...settingsDialog.props}
            />
          </>
        </Hidden>
      </>
    );
  }
}

DialogHost.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,
  dialogs: PropTypes.object.isRequired,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withUserContext(DialogHost);
