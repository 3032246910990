// @ts-nocheck
//all imports
import React from 'react';

import {
  DialogContent,
  DialogActions,
  Typography,
  Hidden,
  Grid,
  Button,
  TextField,
} from '@mui/material';

//all styles

function OrgSignupNewOrgStep(props) {
  //props decomposition
  const { performingAction, organisation, errors } = props;
  return (
    <>
      <Hidden smDown>
        <DialogContent>
          <Grid container direction='row'>
            <Grid container direction='column' spacing={2}>
              <Grid item xs>
                <Typography gutterBottom>
                  What is your Organisation&apos;s name?
                </Typography>
                <TextField
                  disabled={performingAction}
                  error={!!(errors && errors.organisation)}
                  fullWidth
                  helperText={
                    errors && errors.organisation ? errors.organisation[0] : ''
                  }
                  label='Organisation Name'
                  placeholder='My Company Name Ltd'
                  required
                  type='text'
                  value={organisation}
                  variant='outlined'
                  InputLabelProps={{ required: false }}
                  onChange={props.handleOrganisationChange}
                />
              </Grid>

              {/* <Grid item xs>
                  <TextField
                    disabled={performingAction}
                    error={!!(errors && errors.subdomain)}
                    fullWidth
                    helperText={
                      errors && errors.subdomain
                        ? errors.subdomain[0]
                        : ""
                    }
                    label="subdomain"
                    placeholder="agreatorgltd"
                    required
                    type="text"
                    value={subdomain}
                    variant="outlined"
                    InputLabelProps={{
                      startAdornment: <InputAdornment position="start">.broadcaster.vc</InputAdornment>,
                      required: false
                    }}
                    onChange={props.handleSubdomainChange}
                  />
                </Grid> */}
            </Grid>
          </Grid>
        </DialogContent>
      </Hidden>

      <Hidden smUp>
        <DialogContent>
          <Grid container direction='column' spacing={2}>
            <Grid item xs>
              <Typography gutterBottom>
                What is your Organisation&apos;s name?
              </Typography>
              <TextField
                disabled={performingAction}
                error={!!(errors && errors.organisation)}
                fullWidth
                helperText={
                  errors && errors.organisation ? errors.organisation[0] : ''
                }
                label='Organisation Name'
                placeholder='My Company Name Ltd'
                required
                type='text'
                value={organisation}
                variant='outlined'
                InputLabelProps={{ required: false }}
                onChange={props.handleOrganisationChange}
              />
            </Grid>

            {/* <Grid item xs>
              <TextField
                disabled={performingAction}
                error={!!(errors && errors.subdomain)}
                fullWidth
                helperText={
                  errors && errors.subdomain
                    ? errors.subdomain[0]
                    : ""
                }
                label="subdomain"
                placeholder="agreatorgltd"
                required
                type="text"
                value={subdomain}
                variant="outlined"
                InputLabelProps={{
                  startAdornment: <InputAdornment position="start">.broadcaster.vc</InputAdornment>,
                  required: false
                }}
                onChange={props.handleSubdomainChange}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
      </Hidden>

      <DialogActions>
        <Button
          color='primary'
          disabled={performingAction}
          variant='outlined'
          onClick={props.showInitialStep}
        >
          Back
        </Button>
        <Button
          color='primary'
          disabled={
            !organisation ||
            // !subdomain ||
            performingAction
          }
          variant='contained'
          onClick={props.signUpOrganisation}
        >
          Finish Setup
        </Button>
      </DialogActions>
    </>
  );
}

export default OrgSignupNewOrgStep;
