// @ts-nocheck
import { httpsCallable } from 'firebase/functions';
import {
  serverTimestamp,
  doc,
  getDocFromServer,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import {
  OAuthProvider,
  deleteUser,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  linkWithPopup,
  unlink,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  getIdTokenResult,
  signInAnonymously as firebaseSignInAnonymously,
  getIdToken,
  sendEmailVerification,
  updateEmail,
  updatePassword,
} from 'firebase/auth';
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from 'firebase/storage';
import { logEvent } from 'firebase/analytics';
import dayjs from 'dayjs';

import { analytics, auth, firestore, storage, functions } from '../firebase';

export const signUp = (fields) => {
  return new Promise((resolve, reject) => {
    if (!fields) {
      reject(new Error('No fields'));

      return;
    }

    const firstName = fields.firstName;
    const lastName = fields.lastName;
    const emailAddress = fields.emailAddress;
    const password = fields.password;

    if (!firstName || !lastName || !emailAddress || !password) {
      reject(
        new Error('No first name, last name, e-mail address, or password'),
      );

      return;
    }

    if (auth.currentUser) {
      reject(new Error('No current user'));

      return;
    }

    createUserWithEmailAndPassword(auth, emailAddress, password)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error('No user'));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error('No UID'));

          return;
        }

        const userDocumentReference = doc(firestore, 'users', uid);

        setDoc(userDocumentReference, {
          firstName: firstName,
          lastName: lastName,
        })
          .then((value) => {
            logEvent(analytics, 'sign_up', {
              method: 'password',
            });

            resolve(value);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const signUpWithEmailAddressAndPassword = (emailAddress, password) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject(new Error('No e-mail address or password'));

      return;
    }

    if (auth.currentUser) {
      reject(new Error('No current user'));

      return;
    }

    createUserWithEmailAndPassword(auth, emailAddress, password)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error('No user'));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error('No UID'));

          return;
        }

        const userDocumentReference = doc(firestore, 'users', uid);

        setDoc(userDocumentReference, {}, { merge: true })
          .then((value) => {
            logEvent(analytics, 'sign_up', {
              method: 'password',
            });

            resolve(value);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const signIn = (emailAddress, password) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject(new Error('No e-mail address or password'));

      return;
    }

    if (auth.currentUser && !auth.currentUser.isAnonymous) {
      reject(new Error('User already signed in'));

      return;
    }

    signInWithEmailAndPassword(auth, emailAddress, password)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error('No user'));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error('No UID'));

          return;
        }

        const userDocumentReference = doc(firestore, 'users', uid);

        getDocFromServer(userDocumentReference)
          .then((value) => {
            if (value.exists()) {
              logEvent(analytics, 'login', {
                method: 'password',
              });

              resolve(user);
            } else {
              setDoc(userDocumentReference, {}, { merge: true })
                .then(() => {
                  logEvent(analytics, 'login', {
                    method: 'password',
                  });

                  resolve(user);
                })
                .catch((reason) => {
                  reject(reason);
                });
            }
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const sendSignInLinkToEmail = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject(new Error('No e-mail address'));

      return;
    }

    if (auth.currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const actionCodeSettings = {
      url: process.env.VITE_HOMEPAGE,
      handleCodeInApp: true,
    };

    sendSignInLinkToEmail(auth, emailAddress, actionCodeSettings)
      .then((value) => {
        logEvent(analytics, 'send_sign_in_link_to_email');

        localStorage.setItem('emailAddress', emailAddress);

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const signInWithEmailLink = (emailAddress, emailLink) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !emailLink) {
      reject(new Error('No e-mail address or e-mail link'));

      return;
    }

    if (auth.currentUser) {
      reject(new Error('No current user'));

      return;
    }

    signInWithEmailLink(auth, emailAddress, emailLink)
      .then((value) => {
        logEvent(signInWithEmailLink, 'login', {
          method: 'email-link',
        });

        localStorage.removeItem('emailAddress');

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const signInWithAuthProvider = (provider) => {
  return new Promise((resolve, reject) => {
    if (!provider) {
      reject(new Error('No provider'));

      return;
    }

    const authProvider = new OAuthProvider(provider.id);
    const scopes = provider.scopes;

    if (scopes) {
      scopes.forEach((scope) => {
        authProvider.addScope(scope);
      });
    }

    if (auth.currentUser) {
      reject(new Error('No current user'));

      return;
    }

    signInWithPopup(auth, authProvider)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error('No user'));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error('No UID'));

          return;
        }

        const userDocumentReference = doc(firestore, 'users', uid);

        getDocFromServer(userDocumentReference)
          .then((value) => {
            if (value.exists()) {
              logEvent(analytics, 'login', {
                method: provider.id,
              });

              resolve(user);
            } else {
              setDoc(
                userDocumentReference,
                { firstName: user.displayName || null },
                { merge: true },
              )
                .then(() => {
                  logEvent(analytics, 'login', {
                    method: provider.id,
                  });

                  resolve(user);
                })
                .catch((reason) => {
                  reject(reason);
                });
            }
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const linkAuthProvider = (provider) => {
  return new Promise((resolve, reject) => {
    if (!provider) {
      reject(new Error('No provider'));

      return;
    }

    const authProvider = new OAuthProvider(provider.id);
    const scopes = provider.scopes;

    if (scopes) {
      scopes.forEach((scope) => {
        authProvider.addScope(scope);
      });
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    linkWithPopup(currentUser, authProvider)
      .then((value) => {
        logEvent(analytics, 'link_auth_provider', {
          providerId: authProvider.id,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const unlinkAuthProvider = (providerId) => {
  return new Promise((resolve, reject) => {
    if (!providerId) {
      reject(new Error('No provider ID'));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    unlink(currentUser, providerId)
      .then((value) => {
        logEvent(analytics, 'unlink_auth_provider', {
          providerId: providerId,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const authProviderData = (providerId) => {
  if (!providerId) {
    return;
  }

  const currentUser = auth.currentUser;

  if (!currentUser) {
    return;
  }

  const providerData = currentUser.providerData;

  if (!providerData) {
    return;
  }

  return providerData.find((authProvider) => authProvider.id === providerId);
};

export const signOut = async () => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error('No current user');
  }
  try {
    await currentUser.auth.signOut();
    logEvent(analytics, 'sign_out');
  } catch (reason) {
    console.error(reason);
    throw reason;
  }
};

export const resetPassword = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject(new Error('No e-mail address'));

      return;
    }

    if (auth.currentUser) {
      reject(new Error('No current user'));

      return;
    }

    sendPasswordResetEmail(auth, emailAddress)
      .then((value) => {
        logEvent(analytics, 'reset_password');

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const changeAvatar = (avatar) => {
  return new Promise((resolve, reject) => {
    if (!avatar) {
      reject(new Error('No avatar'));

      return;
    }

    const avatarFileTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/svg+xml',
    ];

    if (!avatarFileTypes.includes(avatar.type)) {
      reject(new Error('Invalid file type'));

      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      reject(new Error('Invalid size'));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    const avatarReference = ref(storage, `images/avatars/${uid}`);

    uploadBytes(avatarReference, avatar)
      .then((uploadTaskSnapshot) => {
        getDownloadURL(avatarReference)
          .then((value) => {
            updateProfile(currentUser, {
              photoURL: value,
            })
              .then((value) => {
                logEvent(analytics, 'change_avatar');

                resolve(value);
              })
              .catch((reason) => {
                reject(reason);
              });
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const removeAvatar = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    updateProfile(currentUser, {
      photoURL: '',
    })
      .then(() => {
        const avatarReference = ref(storage, `images/avatars/${uid}`);

        deleteObject(avatarReference)
          .then((value) => {
            logEvent(analytics, 'remove_avatar');

            resolve(value);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const changeFirstName = (firstName) => {
  return new Promise((resolve, reject) => {
    if (!firstName) {
      reject(new Error('No first name'));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    const userDocumentReference = doc(firestore, 'users', uid);

    updateDoc(userDocumentReference, {
      firstName: firstName,
    })
      .then((value) => {
        logEvent(analytics, 'change_first_name');

        //FIXME anonymous users have no email. Check email exists before calling update
        const updateUserDetails = httpsCallable(functions, 'email-updateUser');
        updateUserDetails({
          email: currentUser.email,
          first_name: firstName,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const changeLastName = (lastName) => {
  return new Promise((resolve, reject) => {
    if (!lastName) {
      reject(new Error('No last name'));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    const userDocumentReference = doc(firestore, 'users', uid);

    updateDoc(userDocumentReference, {
      lastName: lastName,
    })
      .then((value) => {
        logEvent(analytics, 'change_last_name');

        const updateUserDetails = httpsCallable(functions, 'email-updateUser');
        updateUserDetails({
          email: currentUser.email,
          last_name: lastName,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const changeEmailAddress = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject(new Error('No e-mail address'));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    const oldEmail = currentUser.email;

    updateEmail(currentUser, emailAddress)
      .then((value) => {
        logEvent(analytics, 'change_email_address');

        const updateUserDetails = httpsCallable(
          functions,
          'email-updateUserEmail',
        );
        updateUserDetails({
          oldEmail,
          newEmail: emailAddress,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const changePassword = (password) => {
  return new Promise((resolve, reject) => {
    if (!password) {
      reject(new Error('No password'));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    updatePassword(currentUser, password)
      .then(() => {
        const userDocumentReference = doc(firestore, 'users', uid);

        updateDoc(userDocumentReference, {
          lastPasswordChange: serverTimestamp(),
        })
          .then((value) => {
            logEvent(analytics, 'change_password');

            resolve(value);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const verifyEmailAddress = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    sendEmailVerification(currentUser)
      .then((value) => {
        logEvent(analytics, 'verify_email_address');

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const deleteAccount = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    deleteUser(currentUser)
      .then((value) => {
        logEvent(analytics, 'delete_account');

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    resolve(currentUser);
  });
};

export const getOrgId = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    getIdTokenResult(currentUser)
      .then((idTokenResult) => {
        resolve(idTokenResult.claims.orgId);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const getRoles = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    getIdTokenResult(currentUser)
      .then((idTokenResult) => {
        resolve(idTokenResult.claims.roles);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const isAdmin = () => {
  return new Promise((resolve, reject) => {
    getRoles()
      .then((value) => {
        resolve(value.includes('admin'));
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const isPremium = () => {
  return new Promise((resolve, reject) => {
    getRoles()
      .then((value) => {
        resolve(value.includes('premium'));
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const getName = (fields) => {
  if (!fields) {
    return null;
  }

  const firstName = fields.firstName;
  const displayName = fields.displayName;
  const lastName = fields.lastName;

  if (firstName) {
    return firstName;
  }

  if (displayName) {
    return displayName;
  }

  if (lastName) {
    return lastName;
  }

  return null;
};

export const getFullName = (fields) => {
  if (!fields) {
    return null;
  }

  const firstName = fields.firstName;
  const lastName = fields.lastName;
  const displayName = fields.displayName;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (displayName) {
    return displayName;
  }

  return null;
};

export const getNameInitials = (fields) => {
  if (!fields) {
    return null;
  }

  const firstName = fields.firstName;
  const lastName = fields.lastName;
  const displayName = fields.displayName;

  if (firstName && lastName) {
    return firstName.charAt(0) + lastName.charAt(0);
  }

  if (firstName) {
    return firstName.charAt(0);
  }

  if (lastName) {
    return lastName.charAt(0);
  }

  if (displayName) {
    return displayName.charAt(0);
  }

  return null;
};

export const getProfileCompletion = (fields) => {
  if (!fields) {
    return null;
  }

  fields = [
    fields.photoURL,
    fields.firstName,
    fields.lastName,
    fields.email,
    fields.email && fields.emailVerified,
  ];

  if (!fields) {
    return null;
  }

  let profileCompletion = 0;

  fields.forEach((field) => {
    if (field) {
      profileCompletion += 100 / fields.length;
    }
  });

  return Math.floor(profileCompletion);
};

export const getSecurityRating = (user, userData) => {
  if (!user || !user.metadata) {
    return null;
  }

  let creationTime = user.metadata.creationTime;

  if (!creationTime) {
    return null;
  }

  creationTime = dayjs(creationTime);

  let securityRating = 0;

  if (userData && userData.lastPasswordChange) {
    let lastPasswordChange = userData.lastPasswordChange;

    if (lastPasswordChange) {
      lastPasswordChange = dayjs(lastPasswordChange.toDate());

      if (creationTime.diff(lastPasswordChange, 'days') >= 365.242199) {
        securityRating = 50;
      } else {
        securityRating = 100;
      }
    }
  } else {
    if (dayjs().diff(creationTime, 'days') >= 365.242199) {
      securityRating = 50;
    } else {
      securityRating = 100;
    }
  }

  return securityRating;
};

export const refreshToken = () => {
  return getIdToken(auth.currentUser, true);
};

export const signInAnonymously = async () => {
  try {
    const anonymous = await firebaseSignInAnonymously(auth);

    console.log(anonymous);
    const uid = anonymous.user.uid;

    if (!uid) {
      throw new Error('No UID');
    }

    const userDocumentReference = doc(firestore, 'users', uid);

    setDoc(
      userDocumentReference,
      { firstName: '', isAnonymous: true },
      { merge: true },
    )
      .then((value) => {
        logEvent(analytics, 'sign_up', {
          method: 'anonymous',
        });
        return value;
      })
      .catch((reason) => {
        throw reason;
      });
  } catch (reason) {
    console.error(reason);
    throw reason;
  }
};
