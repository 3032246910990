// @ts-nocheck
import React from 'react';

import {
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@mui/material';

//all styles

function OrgSignupInitialStep(props) {
  const { performingAction } = props;
  return (
    <>
      <DialogContent>
        <Typography>
          Join or create a Broadcast Bridge Organisation. To join an existing
          Organisation you will need an Organisation ID and a Link Code.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          color='primary'
          disabled={performingAction}
          variant='contained'
          onClick={props.showOrgSetupStep}
        >
          Setup a new Organisation
        </Button>
        <Button
          color='primary'
          disabled={performingAction}
          variant='contained'
          onClick={props.showJoinOrgStep}
        >
          Join an existing Organisation
        </Button>
      </DialogActions>
    </>
  );
}

export default OrgSignupInitialStep;
