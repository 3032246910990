// @ts-nocheck
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

const initialState = {};

class DeleteAccountDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleKeyPress = (event) => {
    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      this.props.deleteAccount();
    }
  };

  handleExited = () => {
    this.setState(initialState);
  };

  render() {
    // Dialog Properties
    const { dialogProps } = this.props;

    // Custom Properties
    const { performingAction } = this.props;

    // Custom Functions
    const { deleteAccount } = this.props;

    return (
      <Dialog
        {...dialogProps}
        onKeyPress={this.handleKeyPress}
        TransitionProps={{
          onExited: this.handleExited,
        }}
      >
        <DialogTitle>Delete account?</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Deleted accounts can’t be recovered. All data associated with your
            account will be deleted.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color='secondary'
            disabled={performingAction}
            onClick={dialogProps.onClose}
          >
            Cancel
          </Button>
          <Button
            color='secondary'
            disabled={performingAction}
            variant='contained'
            onClick={deleteAccount}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteAccountDialog.propTypes = {
  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Properties
  performingAction: PropTypes.bool.isRequired,
  userData: PropTypes.object,

  // Custom Functions
  deleteAccount: PropTypes.func.isRequired,
};

export default DeleteAccountDialog;
