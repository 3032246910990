// @ts-nocheck
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import validate from 'validate.js';

import withStyles from '@mui/styles/withStyles';
import { withSubscriptionContext } from '../../contexts/SubscriptionContext';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  Grid,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

import constraints from '../../data/constraints';
import { create } from '../../services/rooms';
import { list } from '../../services/bridges';
import { firestore } from '../../firebase';

import { collection, query, where, getDocs } from 'firebase/firestore';

import {
  videoCodecsAvailable,
  maxParticipantsLimit,
} from '../../data/planLimits';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: 'auto',
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
});

const initialState = {
  performingAction: false,
  roomName: '',
  roomPassword: '',
  errors: null,
  wssUri: '',
  wssUriOptions: [],
  videoSVC: false,
  bridgeOptions: [],
  bridge: '',
};
class CreateRoomDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  async componentDidUpdate(prevProps) {
    if (this.props.dialogProps.open !== prevProps.dialogProps.open) {
      const wssUriOptions = [];
      await Promise.all([
        new Promise(async (resolve) => {
          const snapshot = await getDocs(
            query(
              collection(firestore, 'publicServers'),
              where('active', '==', true),
            ),
          );

          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              const data = doc.data();
              wssUriOptions.push({ display: doc.id, value: data.uri });
            });
          }
          resolve();
        }),
        new Promise(async (resolve) => {
          if (this.props.user?.orgId) {
            const snapshot = await getDocs(
              query(
                collection(
                  firestore,
                  `organisations/${this.props.user.orgId}/privateServers`,
                ),
                where('active', '==', true),
              ),
            );

            if (!snapshot.empty) {
              snapshot.forEach((doc) => {
                const data = doc.data();
                wssUriOptions.push({
                  display: `Private Server - ${doc.id}`,
                  value: data.uri,
                });
              });
            }
          }
          resolve();
        }),
        new Promise(async (resolve) => {
          const bridgesList = await list();
          if (bridgesList) {
            const bridgeOptions = bridgesList.map((doc) => {
              return {
                display: doc.name ? doc.name : doc.id + ' (Auto Generated)',
                value: doc.id,
              };
            });
            this.setState({ bridgeOptions });
          }
          resolve();
        }),
      ]);
      this.setState({ wssUriOptions });
    }
  }

  createRoom = () => {
    //const { roomName, roomPassword } = this.state;
    const { roomName, wssUri, videoSVC, bridge } = this.state;
    const { history, dialogProps, orgSubscription } = this.props;

    let allowedCodecs = [];
    if (!orgSubscription || orgSubscription.role === 'mega') {
      allowedCodecs = videoCodecsAvailable.mega;
    } else {
      allowedCodecs = videoCodecsAvailable.micro;
    }

    let maxParticipants = maxParticipantsLimit.unsubscribed;
    if (
      orgSubscription &&
      ['active', 'trialing'].includes(orgSubscription.status) &&
      ['nano', 'micro', 'mega'].includes(orgSubscription.role)
    ) {
      const role = orgSubscription.role;
      maxParticipants = maxParticipantsLimit[role];
    }

    let systemDisabled = false;
    const { bandwidthUsage, bandwidthLimit } = orgSubscription;
    if (bandwidthLimit && bandwidthUsage >= bandwidthLimit) {
      systemDisabled = true;
    }

    const errors = validate(
      {
        roomName,
        wssUri,
        bridge,
      },
      {
        roomName: constraints.roomName,
        wssUri: constraints.wssUri,
        bridge: constraints.bridge,
      },
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          errors: null,
        },
        () => {
          this.setState(
            {
              performingAction: true,
            },
            () => {
              create({
                roomName,
                wssUri,
                videoCodecs: allowedCodecs,
                maxParticipants,
                videoSVC,
                bridge,
                systemDisabled,
              })
                .then((value) => {
                  this.props.openSnackbar(`Created Room`);
                  dialogProps.onCloseDialog();
                  history.push(`/rooms/${value.id}`);
                })
                .catch((reason) => {
                  const code = reason.code;
                  const message = reason.message;

                  switch (code) {
                    case 'auth/invalid-email':
                    case 'auth/missing-android-pkg-name':
                    case 'auth/missing-continue-uri':
                    case 'auth/missing-ios-bundle-id':
                    case 'auth/invalid-continue-uri':
                    case 'auth/unauthorized-continue-uri':
                    case 'auth/user-not-found':
                      this.props.openSnackbar(message);
                      return;

                    default:
                      this.props.openSnackbar(message);
                      return;
                  }
                })
                .finally(() => {
                  this.setState({
                    performingAction: false,
                  });
                });
            },
          );
        },
      );
    }
  };

  handleKeyPress = (event) => {
    const { roomName } = this.state;

    if (!roomName) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      this.createRoom();
    }
  };

  handleExited = () => {
    this.setState(initialState);
  };

  handleRoomNameChange = (event) => {
    const roomName = event.target.value;

    this.setState({
      roomName,
    });
  };

  handleBridgeChange = (event) => {
    const bridge = event.target.value;

    this.setState({
      bridge,
    });
  };

  handleWssUriChange = (event) => {
    const wssUri = event.target.value;

    this.setState({
      wssUri,
    });
  };

  handlePasswordChange = (event) => {
    const roomPassword = event.target.value;

    this.setState({
      roomPassword,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;
    const { onCloseDialog, ...passedDialogProps } = dialogProps;

    const {
      performingAction,
      roomName,
      wssUri,
      errors,
      wssUriOptions,
      bridge,
      bridgeOptions,
    } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        {...passedDialogProps}
        onKeyPress={this.handleKeyPress}
        TransitionProps={{
          onExited: this.handleExited,
        }}
        onClose={(event, reason) => {
          if (performingAction) {
            return false;
          }
          onCloseDialog(event, reason);
        }}
      >
        <DialogTitle>
          Create a Broadcast Room
          <Tooltip title='Close'>
            <IconButton
              className={classes.closeButton}
              disabled={performingAction}
              onClick={onCloseDialog}
              size='large'
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Grid container direction='row'>
            <Grid item xs={7}>
              <Grid container direction='column' spacing={2}>
                <Grid item xs sx={{ mt: 1 }}>
                  <TextField
                    disabled={performingAction}
                    error={!!(errors && errors.roomName)}
                    fullWidth
                    helperText={
                      errors && errors.roomName ? errors.roomName[0] : ''
                    }
                    label='Room Name'
                    placeholder='A Great Room'
                    required
                    type='text'
                    value={roomName}
                    variant='outlined'
                    InputLabelProps={{ required: false }}
                    onChange={this.handleRoomNameChange}
                  />
                </Grid>

                <Grid item xs>
                  <FormControl
                    disabled={performingAction}
                    fullWidth
                    variant='outlined'
                  >
                    <InputLabel>Media Server Location</InputLabel>

                    <Select
                      value={wssUri}
                      onChange={this.handleWssUriChange}
                      label='Media Server Location'
                    >
                      {wssUriOptions.map((res) => {
                        return (
                          <MenuItem key={res.value} value={res.value}>
                            {res.display}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs>
                  <FormControl
                    disabled={performingAction}
                    fullWidth
                    variant='outlined'
                  >
                    <InputLabel>Bridge</InputLabel>

                    <Select
                      value={bridge}
                      onChange={this.handleBridgeChange}
                      label='Bridge'
                    >
                      {bridgeOptions.length ? (
                        bridgeOptions.map((res) => {
                          return (
                            <MenuItem key={res.value} value={res.value}>
                              {res.display}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem value=''>Create a Bridge First</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color='primary'
            disabled={!roomName || !bridge || performingAction}
            variant='outlined'
            onClick={this.createRoom}
          >
            Create Room
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateRoomDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withRouter(withSubscriptionContext(CreateRoomDialog)),
);
