// @ts-nocheck
//all imports
import React from 'react';

import {
  DialogContent,
  DialogActions,
  Typography,
  Hidden,
  Grid,
  Button,
  TextField,
} from '@mui/material';

//all styles

function OrgSignupJoinOrgStep(props) {
  //props decomposition
  const { performingAction, organisationId, linkCode, errors } = props;
  return (
    <>
      <Hidden smDown>
        <DialogContent>
          <Grid container direction='row'>
            <Grid container direction='column' spacing={2}>
              <Grid item xs>
                <Typography gutterBottom>
                  Know your Organisation ID and Link Code?
                </Typography>
                <TextField
                  disabled={performingAction}
                  error={!!(errors && errors.organisationId)}
                  fullWidth
                  helperText={
                    errors && errors.organisationId
                      ? errors.organisationId[0]
                      : ''
                  }
                  label='Organisation ID'
                  placeholder='kjlkjjjlkldsjaaaad'
                  required
                  type='text'
                  value={organisationId}
                  variant='outlined'
                  InputLabelProps={{ required: false }}
                  onChange={props.handleOrganisationIdChange}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  disabled={performingAction}
                  error={!!(errors && errors.linkCode)}
                  fullWidth
                  helperText={
                    errors && errors.linkCode ? errors.linkCode[0] : ''
                  }
                  label='Link Code'
                  placeholder='7693y39j'
                  required
                  type='text'
                  value={linkCode}
                  variant='outlined'
                  InputLabelProps={{ required: false }}
                  onChange={props.handleLinkCodeChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Hidden>

      <Hidden smUp>
        <DialogContent>
          <Grid container direction='column' spacing={2}>
            <Grid item xs>
              <Typography gutterBottom>
                Know your Organisation ID and Link Code?
              </Typography>
              <TextField
                disabled={performingAction}
                error={!!(errors && errors.organisationId)}
                fullWidth
                helperText={
                  errors && errors.organisationId
                    ? errors.organisationId[0]
                    : ''
                }
                label='Organisation ID'
                placeholder='kjlkjjjlkldsjaaaad'
                required
                type='text'
                value={organisationId}
                variant='outlined'
                InputLabelProps={{ required: false }}
                onChange={props.handleOrganisationIdChange}
              />
            </Grid>

            <Grid item xs>
              <TextField
                disabled={performingAction}
                error={!!(errors && errors.linkCode)}
                fullWidth
                helperText={errors && errors.linkCode ? errors.linkCode[0] : ''}
                label='Link Code'
                placeholder='7693y39j'
                required
                type='text'
                value={linkCode}
                variant='outlined'
                InputLabelProps={{ required: false }}
                onChange={props.handleLinkCodeChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Hidden>

      <DialogActions>
        <Button
          color='primary'
          disabled={performingAction}
          variant='outlined'
          onClick={props.showInitialStep}
        >
          Back
        </Button>
        <Button
          color='primary'
          disabled={!(organisationId && linkCode) || performingAction}
          variant='contained'
          onClick={props.linkOrganisation}
        >
          Link Organisation
        </Button>
      </DialogActions>
    </>
  );
}

export default OrgSignupJoinOrgStep;
