// @ts-nocheck
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { withSubscriptionContext } from '../../contexts/SubscriptionContext';

import {
  Dialog,
  DialogTitle,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
} from '@mui/material';

import {
  Close as CloseIcon,
  AccountCircle as AccountCircleIcon,
  Business as BusinessIcon,
  // Link as LinkIcon,
  Security as SecurityIcon,
  SettingsInputComposite as SettingsInputCompositeIcon,
  Palette as PaletteIcon,
} from '@mui/icons-material';

import SwipeableViews from 'react-swipeable-views';

import AccountTab from '../AccountTab';
import AppearanceTab from '../AppearanceTab';
// import LinksTab from '../LinksTab';
import SecurityTab from '../SecurityTab';
import DevicesTab from '../DevicesTab';
import OrganisationTab from '../OrganisationTab';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});

const tabs = [
  {
    key: 'account',
    icon: <AccountCircleIcon />,
    label: 'Account',
  },

  {
    key: 'organisation',
    icon: <BusinessIcon />,
    label: 'Organisation',
  },

  {
    key: 'devices',
    icon: <SettingsInputCompositeIcon />,
    label: 'Devices',
  },

  {
    key: 'appearance',
    icon: <PaletteIcon />,
    label: 'Appearance',
  },

  // {
  //   key: 'links',
  //   icon: <LinkIcon />,
  //   label: 'Links',
  // },

  {
    key: 'security',
    icon: <SecurityIcon />,
    label: 'Security',
  },
];

const initialState = {
  selectedTab: 0,
};

class SettingsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleExited = () => {
    this.setState(initialState);
  };

  handleTabChange = (event, value) => {
    this.setState({
      selectedTab: value,
    });
  };

  handleIndexChange = (index) => {
    this.setState({
      selectedTab: index,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;

    // Custom Properties
    const { user, userData, theme, logoURL, orgSubscription } = this.props;

    // Custom Functions
    const { openSnackbar, setLogo } = this.props;

    // Custom Functions
    const {
      onDeleteAccountClick,
      onOrgSignupClick,
      onEndTrialClick,
      endTrialEnabled,
    } = this.props;

    const { selectedTab } = this.state;

    const devicesTab = (
      <DevicesTab key='devicesTab' theme={theme} openSnackbar={openSnackbar} />
    );
    const organisationTab = (
      <OrganisationTab
        key='organisationTab'
        user={user}
        openSnackbar={openSnackbar}
        onOrgSignupClick={onOrgSignupClick}
      />
    );

    const appearanceTab = (
      <AppearanceTab
        key='appearanceTab'
        user={user}
        theme={theme}
        logoURL={logoURL}
        setLogo={setLogo}
        openSnackbar={openSnackbar}
      />
    );

    const swipeableViewsTabs = [
      <AccountTab
        key='accountTab'
        user={user}
        userData={userData}
        openSnackbar={openSnackbar}
        onDeleteAccountClick={onDeleteAccountClick}
        onEndTrialClick={onEndTrialClick}
        endTrialEnabled={endTrialEnabled}
      />,
      organisationTab,
      devicesTab,
      appearanceTab,
      // <LinksTab key='linksTab' theme={theme} openSnackbar={openSnackbar} />,
      <SecurityTab
        key='securityTab'
        user={user}
        userData={userData}
        openSnackbar={openSnackbar}
      />,
    ];

    // hopefully there's a better way of doing this
    if (orgSubscription?.role === 'nano') {
      const appearanceTabIdx = tabs.findIndex(
        (tab) => tab.key === 'appearance',
      );
      if (appearanceTabIdx > -1) {
        tabs.splice(appearanceTabIdx, 1);
      }
      const appearanceViewIdx = swipeableViewsTabs.findIndex(
        (view) => view === appearanceTab,
      );
      if (appearanceViewIdx > -1) {
        swipeableViewsTabs.splice(appearanceViewIdx, 1);
      }
    }

    const isLoggedIn = user && !user.isAnonymous;

    return (
      <Dialog
        {...dialogProps}
        TransitionProps={{
          onExited: this.handleExited,
        }}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>
          Settings
          <Tooltip title='Close'>
            <IconButton
              className={classes.closeButton}
              onClick={dialogProps.onClose}
              size='large'
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        {isLoggedIn && (
          <Tabs
            sx={{ overflow: 'initial', minHeight: 'initial' }}
            indicatorColor='primary'
            textColor='primary'
            value={selectedTab}
            onChange={this.handleTabChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            {tabs.map((tab) => {
              return (
                <Tab
                  sx={{ px: 3 }}
                  key={tab.key}
                  icon={tab.icon}
                  label={tab.label}
                />
              );
            })}
          </Tabs>
        )}

        {isLoggedIn ? (
          <SwipeableViews
            index={selectedTab}
            onChangeIndex={this.handleIndexChange}
            // eslint-disable-next-line react/no-children-prop
            children={swipeableViewsTabs}
          />
        ) : (
          devicesTab
        )}
      </Dialog>
    );
  }
}

SettingsDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Properties
  theme: PropTypes.object.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,

  // Custom Events
  onDeleteAccountClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(withSubscriptionContext(SettingsDialog));
