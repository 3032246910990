// @ts-nocheck
import {
  addDoc,
  getDocFromServer,
  doc,
  collection,
  updateDoc,
} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { analytics, firestore } from '../firebase';
import { getCurrentUser, getOrgId } from './authentication';

export const create = async (fields) => {
  return new Promise(async (resolve, reject) => {
    if (!fields) {
      reject(new Error('No fields'));

      return;
    }

    const organisation = fields.organisation;

    if (!organisation) {
      reject(new Error('No room name'));

      return;
    }

    const currentUser = await getCurrentUser();

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    try {
      const newOrgRef = await addDoc(collection(firestore, 'organisations'), {
        organisation,
        createdByUserId: currentUser.uid,
      });

      logEvent(analytics, 'create_organisation');
      resolve(newOrgRef);
    } catch (reason) {
      reject(reason);
    }
  });
};

export const getSubscriptionStatus = () => {
  return new Promise(async (resolve, reject) => {
    const currentUser = await getCurrentUser();

    if (!currentUser) {
      reject(new Error('No current user'));
      return;
    }
    const orgId = await getOrgId();
    if (!orgId) {
      reject(new Error('No organisation id'));

      return;
    }

    try {
      const organisationRef = doc(firestore, 'organisations', orgId);
      const orgDoc = await getDocFromServer(organisationRef);

      logEvent(analytics, 'getSubscriptionStatus');
      const data = orgDoc.data();

      if (data.subscription) {
        //check the code exists
        resolve(data.subscription.status);
      } else {
        resolve(false);
      }
    } catch (reason) {
      reject(reason);
    }
  });
};

export const changeOrgName = (orgName) => {
  return new Promise(async (resolve, reject) => {
    const currentUser = await getCurrentUser();

    if (!orgName) {
      reject(new Error('No organisation name provided'));
      return;
    }

    if (!currentUser) {
      reject(new Error('No current user'));
      return;
    }
    const orgId = await getOrgId();
    if (!orgId) {
      reject(new Error('No organisation id'));
      return;
    }

    try {
      const organisationRef = doc(firestore, 'organisations', orgId);

      const value = await updateDoc(organisationRef, {
        organisation: orgName,
      });
      logEvent(analytics, 'change_org_name');
      resolve(value);
    } catch (reason) {
      reject(reason);
    }
  });
};
