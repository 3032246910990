// @ts-nocheck
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import validate from 'validate.js';

import withStyles from '@mui/styles/withStyles';
import { withSubscriptionContext } from '../../contexts/SubscriptionContext';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  Grid,
  Button,
  TextField,
} from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

import constraints from '../../data/constraints';
import { create } from '../../services/bridges';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: 'auto',
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
});

const initialState = {
  performingAction: false,
  bridgeName: '',
  bridgeDescription: '',
  errors: null,
};
class CreateBridgeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  createBridge = () => {
    const { bridgeName, bridgeDescription } = this.state;
    const { dialogProps } = this.props;

    const errors = validate(
      {
        bridgeName,
        bridgeDescription,
      },
      {
        bridgeName: constraints.bridgeName,
        bridgeDescription: constraints.bridgeDescription,
      },
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          errors: null,
        },
        () => {
          this.setState(
            {
              performingAction: true,
            },
            () => {
              create({
                bridgeName,
                description: bridgeDescription,
              })
                .then(() => {
                  this.props.openSnackbar(`Created Bridge`);
                  dialogProps.onCloseDialog();
                  // eslint-disable-next-line no-restricted-globals
                  location.reload();
                })
                .catch((reason) => {
                  const code = reason.code;
                  const message = reason.message;

                  switch (code) {
                    case 'auth/invalid-email':
                    case 'auth/missing-android-pkg-name':
                    case 'auth/missing-continue-uri':
                    case 'auth/missing-ios-bundle-id':
                    case 'auth/invalid-continue-uri':
                    case 'auth/unauthorized-continue-uri':
                    case 'auth/user-not-found':
                      this.props.openSnackbar(message);
                      return;

                    default:
                      this.props.openSnackbar(message);
                      return;
                  }
                })
                .finally(() => {
                  this.setState({
                    performingAction: false,
                  });
                });
            },
          );
        },
      );
    }
  };

  handleKeyPress = (event) => {
    const { bridgeName } = this.state;

    if (!bridgeName) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      this.createBridge();
    }
  };

  handleExited = () => {
    this.setState(initialState);
  };

  handleBridgeNameChange = (event) => {
    const bridgeName = event.target.value;

    this.setState({
      bridgeName,
    });
  };

  handleBridgeDescriptionChange = (event) => {
    const bridgeDescription = event.target.value;

    this.setState({
      bridgeDescription,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;
    const { onCloseDialog, ...passedDialogProps } = dialogProps;

    const { performingAction, bridgeName, bridgeDescription, errors } =
      this.state;

    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        disableEscapeKeyDown={performingAction}
        {...passedDialogProps}
        onKeyPress={this.handleKeyPress}
        TransitionProps={{
          onExited: this.handleExited,
        }}
        onClose={(event, reason) => {
          if (performingAction) {
            return false;
          }
          onCloseDialog(event, reason);
        }}
      >
        <DialogTitle>
          Create a Bridge
          <Tooltip title='Close'>
            <IconButton
              className={classes.closeButton}
              disabled={performingAction}
              onClick={onCloseDialog}
              size='large'
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Grid container direction='row' mt={1}>
            <Grid item xs={7}>
              <Grid container direction='column' spacing={2}>
                <Grid item xs>
                  <TextField
                    disabled={performingAction}
                    error={!!(errors && errors.bridgeName)}
                    fullWidth
                    helperText={
                      errors && errors.bridgeName ? errors.bridgeName[0] : ''
                    }
                    label='Bridge Name'
                    placeholder='A Brilliant Bridge'
                    required
                    type='text'
                    value={bridgeName}
                    variant='outlined'
                    InputLabelProps={{ required: false }}
                    onChange={this.handleBridgeNameChange}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    disabled={performingAction}
                    error={!!(errors && errors.bridgeDescription)}
                    fullWidth
                    helperText={
                      errors && errors.bridgeDescription
                        ? errors.bridgeDescription[0]
                        : ''
                    }
                    label='Bridge Description'
                    placeholder='Dependable Bridge'
                    required
                    type='text'
                    value={bridgeDescription}
                    variant='outlined'
                    InputLabelProps={{ required: false }}
                    onChange={this.handleBridgeDescriptionChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color='primary'
            disabled={!bridgeName || performingAction}
            variant='outlined'
            onClick={this.createBridge}
          >
            Create Bridge
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateBridgeDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withRouter(withSubscriptionContext(CreateBridgeDialog)),
);
