// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import validate from 'validate.js';

import {
  Box,
  Button,
  Chip,
  DialogContent,
  Divider,
  Hidden,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
} from '@mui/material';

import {
  Person as PersonIcon,
  GroupAdd as GroupAddIcon,
  Business as BusinessIcon,
  EventSeat as EventSeatIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import { Skeleton } from '@mui/material';

import { ContentCopy as ContentCopyIcon } from 'mdi-material-ui';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

import { functions, firestore } from '../../firebase';
import { httpsCallable } from 'firebase/functions';

import constraints from '../../data/constraints';
import { changeOrgName } from '../../services/organisations';

const styles = (theme) => ({
  linkCodeChip: {
    width: 145,
    display: 'flex',
    justifyContent: 'space-between',
  },

  dialogContent: {
    paddingTop: theme.spacing(2),
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  loadingBadge: {
    top: '50%',
    right: '50%',
  },

  avatar: {
    marginRight: 'auto',
    marginLeft: 'auto',

    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  nameInitials: {
    cursor: 'default',
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: 'initial',
  },
});

const initialState = {
  performingAction: false,
  errors: null,
  orgName: '',
  orgNameInput: '',
  usersList: [],
  codesList: [],
  showingField: '',
};

const getUsersInOrg = httpsCallable(functions, 'plans-getUsersInOrg');

class OrganisationTab extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleCodeCopy = (code) => {
    navigator.clipboard.writeText(code);
  };

  displayName = (user) => {
    const defaultName = 'Broadcast Bridge User';
    if (!user) {
      return defaultName;
    }

    if (user.firstName) {
      return `${user.firstName} ${user.lastName || ''}`.replace(/[\t]$/, '');
    } else {
      return user.lastName || user.email || defaultName;
    }
  };

  showField = (fieldId) => {
    if (!fieldId) {
      return;
    }

    this.setState({
      showingField: fieldId,
    });
  };

  hideFields = (callback) => {
    this.setState(
      {
        showingField: '',
        errors: null,
      },
      () => {
        if (callback && typeof callback === 'function') {
          callback();
        }
      },
    );
  };

  changeField = (fieldId) => {
    switch (fieldId) {
      case 'org-name':
        this.changeOrgName();
        return;
      default:
        return;
    }
  };

  handleBlur = (event, fieldId) => {
    if (!event || !fieldId) {
      return;
    }
    this.hideFields();
  };

  handleKeyDown = (event, fieldId) => {
    if (!event || !fieldId) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    const key = event.key;

    if (!key) {
      return;
    }

    if (key === 'Escape') {
      this.hideFields();
    } else if (key === 'Enter') {
      this.changeField(fieldId);
    }
  };

  changeOrgName = () => {
    const { orgName, orgNameInput } = this.state;

    const errors = validate(
      {
        orgNameInput,
      },
      {
        orgNameInput: constraints.firstName,
      },
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        if (orgNameInput === orgName) {
          this.hideFields();
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            changeOrgName(orgNameInput)
              .then(() => {
                this.setState(
                  {
                    orgName: orgNameInput,
                  },
                  () => {
                    this.hideFields();
                  },
                );
              })
              .catch((reason) => {
                this.props.openSnackbar(
                  `Error renaming organisation: ${reason.message}`,
                );
                return;
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          },
        );
      },
    );
  };

  handleOrgNameChange = (event) => {
    if (!event) {
      return;
    }

    const orgNameInput = event.target.value;

    this.setState({ orgNameInput });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { user, onOrgSignupClick } = this.props;

    const {
      performingAction,
      orgName,
      usersList,
      codesList,
      showingField,
      errors,
      orgNameInput,
    } = this.state;

    const seatsUsed = usersList.length;
    const seatsTot = usersList.length + codesList.length;
    const orgCreator = usersList.find((user) => user.isCreator);

    return (
      <DialogContent classes={{ root: classes.dialogContent }}>
        <List disablePadding>
          {!user.orgId ? (
            <ListItem>
              <ListItemText
                primary='No Organisation'
                secondary='Please create or link to an organisation to access Broadcast Bridge’s features'
              />

              <ListItemSecondaryAction>
                <Button
                  color='primary'
                  disabled={performingAction}
                  variant='contained'
                  onClick={onOrgSignupClick}
                >
                  Setup
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          ) : (
            <>
              {showingField !== 'org-name' ? (
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant='h4'>
                        {orgName || <Skeleton width='220px' height='50px' />}
                      </Typography>
                    }
                    secondary={
                      orgName ? (
                        `Created by ${this.displayName(orgCreator)}`
                      ) : (
                        <Skeleton width='220px' />
                      )
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title='Change'>
                      <div>
                        <IconButton
                          disabled={!orgName || performingAction}
                          onClick={() => this.showField('org-name')}
                          size='large'
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ) : (
                <TextField
                  autoFocus
                  disabled={performingAction}
                  error={!!(errors && errors.orgNameInput)}
                  fullWidth
                  helperText={
                    errors && errors.orgNameInput
                      ? errors.orgNameInput[0]
                      : "Press Enter to change your organisation's name"
                  }
                  label='Organisation name'
                  placeholder={orgName}
                  required
                  type='text'
                  value={orgNameInput}
                  variant='filled'
                  InputLabelProps={{ required: false }}
                  onBlur={(event) => this.handleBlur(event, 'org-name')}
                  onKeyDown={(event) => this.handleKeyDown(event, 'org-name')}
                  onChange={this.handleOrgNameChange}
                />
              )}

              <Box mt={1} mb={1}>
                <Divider light />
              </Box>

              <ListItem>
                <Hidden smDown>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                </Hidden>

                <ListItemText primary={<Typography>Users</Typography>} />
                <ListItemSecondaryAction>
                  <Typography color='textSecondary'>
                    {seatsUsed ? (
                      `${seatsUsed} of ${seatsTot} seats used`
                    ) : (
                      <Skeleton width='130px' />
                    )}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>

              {usersList.length ? (
                <ListItem>
                  <List>
                    {usersList.map((user, key) => {
                      const displayName = this.displayName(user);
                      return <ListItem key={key}>{displayName}</ListItem>;
                    })}
                  </List>
                </ListItem>
              ) : (
                <Skeleton width='180px' height='100px' />
              )}

              <Box mt={1} mb={1}>
                <Divider light />
              </Box>

              <ListItem>
                <Hidden smDown>
                  <ListItemIcon>
                    <GroupAddIcon />
                  </ListItemIcon>
                </Hidden>

                <ListItemText
                  primary='Adding Users'
                  secondary='To add new users, give them your Organisation ID and the link code to one available seat'
                />
              </ListItem>

              <ListItem>
                <Hidden smDown>
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                </Hidden>

                <ListItemText
                  primary='Organisation ID'
                  secondary={user.orgId}
                />
                {navigator?.clipboard?.writeText && (
                  <ListItemSecondaryAction>
                    <Tooltip title='Copy'>
                      <IconButton
                        edge='end'
                        aria-label='copy-api-key'
                        onClick={(evt) => {
                          evt.preventDefault();
                          navigator.clipboard.writeText(user.orgId);
                        }}
                        size='large'
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>

              <ListItem>
                <Hidden smDown>
                  <ListItemIcon>
                    <EventSeatIcon />
                  </ListItemIcon>
                </Hidden>

                <ListItemText
                  primary={<Typography>Seats Available</Typography>}
                  secondary={
                    orgName &&
                    !codesList.length &&
                    'There are no seats available'
                  }
                />
              </ListItem>

              {orgName ? (
                <ListItem>
                  {codesList.length ? (
                    <List>
                      {codesList.map((code) => {
                        return (
                          <ListItem key={code}>
                            <Chip
                              className={classes.linkCodeChip}
                              label={code}
                              variant='outlined'
                              clickable={false}
                              onDelete={() =>
                                navigator.clipboard.writeText(code)
                              }
                              deleteIcon={
                                navigator?.clipboard?.writeText && (
                                  <Tooltip title='Copy Link Code'>
                                    <IconButton
                                      edge='end'
                                      aria-label='copy-link-code'
                                      size='large'
                                    >
                                      <ContentCopyIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  ) : null}
                </ListItem>
              ) : (
                <Skeleton width='180px' height='100px' />
              )}
            </>
          )}
        </List>
      </DialogContent>
    );
  }

  async componentDidMount() {
    const { user } = this.props;

    if (!user.orgId) {
      return;
    }

    try {
      const organisationRef = doc(firestore, 'organisations', user.orgId);
      const organisation = await getDoc(organisationRef);

      const organisationData = organisation.data();
      const orgName = organisationData.organisation;

      const usersObj = await getUsersInOrg();
      const usersList = usersObj.data.sort((user1, user2) => {
        if (user2.isCreator) {
          return 1;
        } else {
          return -1;
        }
      });

      const codesQuery = query(
        collection(firestore, `organisations/${user.orgId}/codes`),
        where('enabled', '==', true),
      );

      const codesSnap = await getDocs(codesQuery);

      const codesList = [];
      codesSnap.docs.forEach((code) => {
        codesList.push(code.id);
      });

      this.setState({
        orgName,
        orgNameInput: orgName,
        usersList,
        codesList,
      });
    } catch (reason) {
      console.log(reason);
    }
  }
}

OrganisationTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  user: PropTypes.object.isRequired,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrganisationTab);
