// @ts-nocheck
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Hidden,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Switch,
  Tooltip,
} from '@mui/material';

import {
  Close as CloseIcon,
  FeaturedVideo as FeaturedVideoIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Brightness4 as Brightness4Icon,
  FormatColorReset as FormatColorResetIcon,
  Photo as PhotoIcon,
} from '@mui/icons-material';

import { doc, updateDoc, getDoc } from 'firebase/firestore';

import {
  isDefaultTheme,
  resetTheme,
  changeLogo,
  removeLogo,
  removeBackground,
  uploadBackground,
  colors,
  changeTheme,
} from '../../services/appearance';
import { firestore } from '../../firebase';

const styles = (theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    minHeight: 'initial',
  },
});

class AppearanceTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      performingAction: false,
      loadingBackground: false,
      loadingLogo: false,
      background: null,
      isBackgroundSaved: true,
      backgroundURL: '',
      backgroundStyle: {
        selectedOption: 'cover',
        backgroundSize: 'cover',
      },
      sliderValue: 40,
    };

    this.primaryColorLabel = React.createRef();
    this.secondaryColorLabel = React.createRef();
  }

  async componentDidMount() {
    try {
      await this.getBackground();
    } catch (reason) {
      console.error(`Error getting background URL: ${reason.message}`);
    }
  }

  componentWillUnmount() {
    const { backgroundURL } = this.state;

    if (backgroundURL) {
      URL.revokeObjectURL(backgroundURL);

      this.setState({
        backgroundURL: '',
      });
    }
  }

  getBackground = async () => {
    const { user } = this.props;
    if (!user || !user.orgId) {
      throw new Error('no user or org');
    }
    const orgDocumentReference = doc(firestore, 'organisations', user.orgId);

    const organisation = await getDoc(orgDocumentReference);
    const orgData = organisation.data();

    if (!orgData || !orgData.background) {
      return;
    }

    this.setState({
      backgroundURL: orgData.background.url,
      backgroundStyle: orgData.background.style,
    });
  };

  handlePrimaryColorChange = (event) => {
    if (!event) {
      return;
    }

    const primaryColor = event.target.value;

    if (!primaryColor) {
      return;
    }

    const { theme } = this.props;

    if (!theme) {
      return;
    }

    if (theme.primaryColor.id === primaryColor) {
      return;
    }

    this.setState(
      {
        performingAction: true,
      },
      () => {
        changeTheme({
          primaryColor: primaryColor,
          secondaryColor: theme.secondaryColor.id,
          dark: theme.dark,
        })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      },
    );
  };

  handleSecondaryColorChange = (event) => {
    if (!event) {
      return;
    }

    const secondaryColor = event.target.value;

    if (!secondaryColor) {
      return;
    }

    const { theme } = this.props;

    if (!theme) {
      return;
    }

    if (theme.secondaryColor.id === secondaryColor) {
      return;
    }

    this.setState(
      {
        performingAction: true,
      },
      () => {
        changeTheme({
          primaryColor: theme.primaryColor.id,
          secondaryColor: secondaryColor,
          dark: theme.dark,
        })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      },
    );
  };

  handleDarkModeChange = (event) => {
    if (!event) {
      return;
    }

    const dark = event.target.checked;

    const { theme } = this.props;

    if (!theme) {
      return;
    }

    if (theme.dark === dark) {
      return;
    }

    this.setState(
      {
        performingAction: true,
      },
      () => {
        changeTheme({
          primaryColor: theme.primaryColor.id,
          secondaryColor: theme.secondaryColor.id,
          dark: dark,
        })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      },
    );
  };

  handleResetThemeClick = () => {
    const { theme } = this.props;

    if (!theme) {
      return;
    }

    if (isDefaultTheme(theme)) {
      return;
    }

    this.setState(
      {
        performingAction: true,
      },
      () => {
        resetTheme()
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      },
    );
  };

  handleLogoChange = (event) => {
    const { openSnackbar, setLogo, user } = this.props;
    if (!event) {
      return;
    }
    if (!user?.orgId) {
      openSnackbar('No orgId');
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const logo = files[0];

    if (!logo) {
      return;
    }

    this.setState(
      {
        performingAction: true,
        loadingLogo: true,
      },
      async () => {
        try {
          const logoURL = await changeLogo(logo);
          openSnackbar('Logo uploaded!');
          setLogo(logoURL);
        } catch (reason) {
          openSnackbar(reason.message);
          return;
        } finally {
          this.setState({
            performingAction: false,
            loadingLogo: false,
          });
        }
      },
    );
  };

  handleBackgroundChange = (event) => {
    const { openSnackbar, user } = this.props;
    if (!event) {
      return;
    }
    if (!user?.orgId) {
      openSnackbar('No orgId');
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const background = files[0];

    if (!background) {
      return;
    }

    this.setState(
      {
        performingAction: true,
        loadingBackground: true,
      },
      () => {
        try {
          this.setState({
            background: background,
            isBackgroundSaved: false,
            backgroundURL: URL.createObjectURL(background),
            backgroundStyle: {
              selectedOption: 'cover',
              backgroundSize: 'cover',
            },
          });
        } catch (reason) {
          this.props.openSnackbar(reason.message);
          return;
        } finally {
          this.setState({
            performingAction: false,
            loadingBackground: false,
          });
        }
      },
    );
  };

  handleBgSizeChange = (event) => {
    if (!event) {
      return;
    }

    const value = event.target.value;

    switch (value) {
      case 'cover': {
        this.setState({
          backgroundStyle: {
            selectedOption: 'cover',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
        });
        break;
      }
      case 'center': {
        this.setState((prevState) => {
          return {
            ...prevState,
            backgroundStyle: {
              selectedOption: 'center',
              backgroundSize: `${prevState.sliderValue}%`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            },
          };
        });
        break;
      }
      case 'repeat': {
        this.setState((prevState) => {
          return {
            ...prevState,
            backgroundStyle: {
              selectedOption: 'repeat',
              backgroundSize: `${prevState.sliderValue}%`,
              backgroundRepeat: 'round',
            },
          };
        });
        break;
      }
      default:
        return;
    }

    this.setState({
      isBackgroundSaved: false,
    });
  };

  handleSlider = (event, newValue) => {
    this.setState((prevState) => {
      const { backgroundStyle } = prevState;

      return {
        ...prevState,
        sliderValue: newValue,
        isBackgroundSaved: false,
        backgroundStyle: {
          ...backgroundStyle,
          backgroundSize: `${newValue}%`,
        },
      };
    });
  };

  removeLogo = () => {
    const { openSnackbar, setLogo } = this.props;
    this.setState(
      {
        performingAction: true,
        loadingLogo: true,
      },
      async () => {
        try {
          await removeLogo();
          openSnackbar('Logo removed!');
          setLogo('');
        } catch (reason) {
          openSnackbar(reason);
          return;
        } finally {
          this.setState({
            performingAction: false,
            loadingLogo: false,
          });
        }
      },
    );
  };

  removeBackground = async () => {
    const { openSnackbar } = this.props;
    const { background, backgroundURL } = this.state;

    if (!backgroundURL) {
      return;
    }

    this.setState({
      performingAction: true,
      loadingBackground: true,
    });

    try {
      if (background) {
        URL.revokeObjectURL(backgroundURL);
      } else {
        await removeBackground();
      }

      this.setState(
        {
          background: null,
          isBackgroundSaved: true,
          backgroundURL: '',
          backgroundStyle: {
            selectedOption: 'cover',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
        },
        () => openSnackbar('Background removed!'),
      );
    } catch (reason) {
      openSnackbar(reason);
      return;
    } finally {
      this.setState({
        performingAction: false,
        loadingBackground: false,
      });
    }
  };

  saveBackground = () => {
    const { user, openSnackbar } = this.props;
    this.setState(
      {
        performingAction: true,
        loadingBackground: true,
      },
      async () => {
        try {
          const { background, backgroundURL, backgroundStyle } = this.state;

          if (background && backgroundURL) {
            URL.revokeObjectURL(backgroundURL);
          }

          let newBgURL = '';
          if (background) {
            newBgURL = await uploadBackground(background);
          } else {
            newBgURL = backgroundURL;
          }

          const orgDocumentReference = doc(
            firestore,
            'organisations',
            user.orgId,
          );

          await updateDoc(orgDocumentReference, {
            background: {
              url: newBgURL,
              style: backgroundStyle,
            },
          });

          this.setState({
            background: null,
            isBackgroundSaved: true,
            backgroundURL: newBgURL,
          });

          openSnackbar('Background saved!');
        } catch (reason) {
          openSnackbar(reason);
          return;
        } finally {
          this.setState({
            performingAction: false,
            loadingBackground: false,
          });
        }
      },
    );
  };

  render() {
    // Properties
    const { theme, logoURL } = this.props;

    // Styling
    const { classes } = this.props;

    if (!theme) {
      return null;
    }

    const {
      performingAction,
      loadingBackground,
      isBackgroundSaved,
      backgroundURL,
      backgroundStyle,
      sliderValue,
    } = this.state;

    const backgroundPreview = {
      width: 600,
      height: (600 * window.innerHeight) / window.innerWidth,
      backgroundImage: `url(${backgroundURL})`,
      ...backgroundStyle,
    };

    const sliderMarks = [
      {
        value: 10,
        label: '10%',
      },
      {
        value: 50,
        label: '50%',
      },
      {
        value: 90,
        label: '90%',
      },
    ];

    return (
      <DialogContent>
        <List disablePadding>
          <Box mb={1}>
            <ListItem>
              <Hidden smDown>
                <ListItemIcon sx={{ color: 'primary.main' }}>
                  <FiberManualRecordIcon />
                </ListItemIcon>
              </Hidden>

              <FormControl
                disabled={performingAction}
                fullWidth
                variant='outlined'
              >
                <InputLabel ref={this.primaryColorLabel}>
                  Primary color
                </InputLabel>

                <Hidden smUp>
                  <Select
                    native
                    value={theme.primaryColor.id}
                    label='Primary color'
                    onChange={this.handlePrimaryColorChange}
                  >
                    {Object.keys(colors).map((color) => {
                      color = colors[color];

                      return (
                        <option key={color.id} value={color.id}>
                          {color.name}
                        </option>
                      );
                    })}
                  </Select>
                </Hidden>

                <Hidden smDown>
                  <Select
                    value={theme.primaryColor.id}
                    label='Primary color'
                    onChange={this.handlePrimaryColorChange}
                  >
                    {Object.keys(colors).map((color) => {
                      color = colors[color];

                      return (
                        <MenuItem key={color.id} value={color.id}>
                          {color.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Hidden>
              </FormControl>
            </ListItem>
          </Box>

          <Box mb={1}>
            <ListItem>
              <Hidden smDown>
                <ListItemIcon sx={{ color: 'secondary.main' }}>
                  <FiberManualRecordIcon />
                </ListItemIcon>
              </Hidden>

              <FormControl
                disabled={performingAction}
                fullWidth
                variant='outlined'
              >
                <InputLabel ref={this.secondaryColorLabel}>
                  Secondary color
                </InputLabel>

                <Hidden smUp>
                  <Select
                    native
                    value={theme.secondaryColor.id}
                    label='Secondary color'
                    onChange={this.handleSecondaryColorChange}
                  >
                    {Object.keys(colors).map((color) => {
                      color = colors[color];

                      return (
                        <option key={color.id} value={color.id}>
                          {color.name}
                        </option>
                      );
                    })}
                  </Select>
                </Hidden>

                <Hidden smDown>
                  <Select
                    value={theme.secondaryColor.id}
                    label='Secondary color'
                    onChange={this.handleSecondaryColorChange}
                  >
                    {Object.keys(colors).map((color) => {
                      color = colors[color];

                      return (
                        <MenuItem key={color.id} value={color.id}>
                          {color.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Hidden>
              </FormControl>
            </ListItem>
          </Box>

          <ListItem>
            <Hidden smDown>
              <ListItemIcon>
                <Brightness4Icon />
              </ListItemIcon>
            </Hidden>

            <ListItemText
              primary='Dark mode'
              secondary='Displays mostly dark surfaces'
            />

            <ListItemSecondaryAction>
              <Hidden smDown>
                <Checkbox
                  color='primary'
                  checked={theme.dark}
                  onChange={this.handleDarkModeChange}
                />
              </Hidden>

              <Hidden smUp>
                <Switch
                  color='primary'
                  checked={theme.dark}
                  onChange={this.handleDarkModeChange}
                />
              </Hidden>
            </ListItemSecondaryAction>
          </ListItem>

          <Box mt={2} mb={1}>
            <Divider light />
          </Box>

          <ListItem>
            <Hidden smDown>
              <ListItemIcon>
                <FormatColorResetIcon />
              </ListItemIcon>
            </Hidden>

            <ListItemText
              primary='Reset theme'
              secondary={
                isDefaultTheme(theme)
                  ? 'No changes made'
                  : 'Changes will be reset'
              }
            />

            <ListItemSecondaryAction>
              <Button
                color='secondary'
                disabled={isDefaultTheme(theme) || performingAction}
                variant='contained'
                onClick={this.handleResetThemeClick}
              >
                Reset
              </Button>
            </ListItemSecondaryAction>
          </ListItem>

          <Box mt={2} mb={1}>
            <Divider light />
          </Box>

          <ListItem>
            <Hidden smDown>
              <ListItemIcon>
                <FeaturedVideoIcon />
              </ListItemIcon>
            </Hidden>

            <ListItemText
              primary='Upload your logo'
              secondary={
                backgroundURL
                  ? 'The new image will replace the current logo'
                  : 'The logo will be visible during sessions'
              }
            />

            <>
              <input
                id='logo-input'
                type='file'
                hidden
                accept='image/*'
                onChange={this.handleLogoChange}
              />

              <label htmlFor='logo-input'>
                <Button
                  color='primary'
                  component='span'
                  disabled={performingAction}
                  variant='contained'
                >
                  Upload
                </Button>
              </label>
            </>
          </ListItem>

          {logoURL && (
            <ListItem>
              <Badge
                badgeContent={
                  <Tooltip title='Remove'>
                    <span>
                      <Fab
                        classes={{ sizeSmall: classes.small }}
                        color='secondary'
                        disabled={performingAction}
                        size='small'
                        onClick={this.removeLogo}
                      >
                        <CloseIcon fontSize='small' />
                      </Fab>
                    </span>
                  </Tooltip>
                }
              >
                <img src={logoURL} alt='Logo preview' width='250px' />
              </Badge>
            </ListItem>
          )}

          <ListItem>
            <Hidden smDown>
              <ListItemIcon>
                <PhotoIcon />
              </ListItemIcon>
            </Hidden>

            <ListItemText
              primary='Upload session background image'
              secondary={
                backgroundURL
                  ? 'The new image will replace the current background'
                  : 'The background will be used during sessions'
              }
            />
            {isBackgroundSaved ? (
              <>
                <input
                  id='background-input'
                  type='file'
                  hidden
                  accept='image/*'
                  onChange={this.handleBackgroundChange}
                />

                <label htmlFor='background-input'>
                  <Button
                    color='primary'
                    component='span'
                    disabled={performingAction}
                    variant='contained'
                  >
                    Upload
                  </Button>
                </label>
              </>
            ) : (
              <Button
                color='primary'
                component='span'
                disabled={performingAction}
                variant='contained'
                onClick={this.saveBackground}
              >
                {loadingBackground ? <CircularProgress size={16} /> : 'Save'}
              </Button>
            )}
          </ListItem>

          {backgroundURL && (
            <ListItem>
              <Badge
                badgeContent={
                  <Tooltip title='Remove'>
                    <Fab
                      classes={{ sizeSmall: classes.small }}
                      color='secondary'
                      disabled={performingAction}
                      size='small'
                      onClick={this.removeBackground}
                    >
                      <CloseIcon fontSize='small' />
                    </Fab>
                  </Tooltip>
                }
              >
                <Box border={1} style={backgroundPreview} />
              </Badge>

              <ListItemSecondaryAction>
                <FormControl>
                  <FormLabel>Background Options</FormLabel>
                  <RadioGroup
                    aria-label='background size options'
                    name='backgroundSizeOptions'
                    value={backgroundStyle.selectedOption}
                    onChange={this.handleBgSizeChange}
                  >
                    <FormControlLabel
                      value='cover'
                      control={<Radio />}
                      label='Cover'
                    />
                    <FormControlLabel
                      value='center'
                      control={<Radio />}
                      label='Centered'
                    />
                    <FormControlLabel
                      value='repeat'
                      control={<Radio />}
                      label='Repeat'
                    />
                  </RadioGroup>
                </FormControl>

                <InputLabel
                  id='background-slider'
                  style={{ marginTop: 20, marginBottom: 40 }}
                >
                  Image Size
                </InputLabel>
                <Slider
                  disabled={backgroundStyle.selectedOption === 'cover'}
                  value={sliderValue}
                  min={10}
                  max={90}
                  onChange={this.handleSlider}
                  aria-labelledby='background-size-slider'
                  marks={sliderMarks}
                  valueLabelDisplay='auto'
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </DialogContent>
    );
  }
}

AppearanceTab.propTypes = {
  // Properties
  theme: PropTypes.object.isRequired,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(AppearanceTab);
