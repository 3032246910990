// @ts-nocheck
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Typography, Box, IconButton, Link } from '@mui/material';
import { Phone as PhoneIcon, Mail as MailIcon } from '@mui/icons-material';
import WaveBorder from '../WaveBorder';

import { withUserContext } from '../../contexts/UserContext';

import {
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
} from 'mdi-material-ui';

import { useLocation } from 'react-router-dom';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
// eslint-disable-next-line react/display-name
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width='xs' />;

const styles = (theme) => ({
  container: {
    flexShrink: 0,
    backgroundColor: 'rgb(36, 40, 44)',
    color: theme.palette.common.white,
  },
  footerInner: {
    backgroundColor: theme.palette.common.darkBlack,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  brandText: {
    fontWeight: 400,
    color: theme.palette.common.white,
  },
  footerLinks: {
    marginTop: theme.spacing(2.5),
    marginBot: theme.spacing(1.5),
    color: theme.palette.common.white,
  },
  infoIcon: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: '#33383b !important',
  },
  socialIcon: {
    fill: theme.palette.common.white,
    backgroundColor: '#33383b',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  link: {
    cursor: 'Pointer',
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  whiteBg: {
    backgroundColor: theme.palette.common.white,
  },
});

const infos = [
  {
    icon: <PhoneIcon />,
    element: (props) => (
      <Link href='tel:+442030263291' {...props} underline='hover'>
        +44 (0)20 3026 3291
      </Link>
    ),
  },
  {
    icon: <MailIcon />,
    element: (props) => (
      <Link
        href='mailto:support@broadcastbridge.app'
        target='_blank'
        rel='noopener noreferrer'
        {...props}
        underline='hover'
      >
        support@broadcastbridge.app
      </Link>
    ),
  },
];

const socialIcons = [
  {
    icon: <TwitterIcon htmlColor='#fff' />,
    label: 'Twitter',
    href: 'https://www.twitter.com/EverycastLabs',
  },
  {
    icon: <FacebookIcon htmlColor='#fff' />,
    label: 'Facebook',
    href: 'https://www.facebook.com/broadcastbridgeapp',
  },
];

function Footer(props) {
  const { classes, user, isInIFrame } = props;

  const location = useLocation();

  if (isInIFrame || user || location.pathname.includes('/ext/')) {
    return <></>;
  }

  return (
    <footer className={classes.container}>
      <WaveBorder
        upperColor='#fafafa'
        lowerColor='rgb(36, 40, 44)'
        animationNegativeDelay={4}
      />
      <div className={classes.footerInner}>
        <Grid container>
          <Grid item xs={12} md={6} lg={4}>
            <Box display='flex' justifyContent='center'>
              <div>
                {infos.map((info, index) => {
                  //console.log(info);
                  const LinkComponent = info.element;
                  return (
                    <Box display='flex' mb={1} key={index}>
                      <Box mr={2}>
                        <IconButton
                          className={classes.infoIcon}
                          tabIndex={-1}
                          disabled
                          size='large'
                        >
                          {info.icon}
                        </IconButton>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                      >
                        <Typography variant='h6' className='text-white'>
                          <LinkComponent className={classes.footerLinks} />
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant='h6' paragraph className='text-white'>
              About Broadcast Bridge
            </Typography>
            <Typography paragraph>
              <Link
                component={RouterLink}
                to='/privacy-policy'
                underline='hover'
              >
                Privacy Policy
              </Link>{' '}
              |{' '}
              <Link
                component={RouterLink}
                to='/terms-and-conditions'
                underline='hover'
              >
                Terms and Conditions
              </Link>
              <br />
              <Link
                href='https://support.broadcastbridge.app/'
                target='_blank'
                rel='noopener noreferrer'
                underline='hover'
              >
                Support
              </Link>{' '}
              |{' '}
              <Link
                target='_blank'
                rel='noopener noreferrer'
                href='https://updates.broadcastbridge.app'
                underline='hover'
              >
                Updates
              </Link>{' '}
              |{' '}
              <Link
                target='_blank'
                rel='noopener noreferrer'
                href='https://status.broadcastbridge.app'
                underline='hover'
              >
                Status
              </Link>
            </Typography>
            <Box display='flex'>
              {socialIcons.map((socialIcon, index) => (
                <Box key={index} mr={index !== socialIcons.length - 1 ? 1 : 0}>
                  <IconButton
                    aria-label={socialIcon.label}
                    className={classes.socialIcon}
                    href={socialIcon.href}
                    size='large'
                  >
                    {socialIcon.icon}
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}

Footer.propTypes = {};

export default withWidth()(
  withStyles(styles, { withTheme: true })(withUserContext(Footer)),
);
