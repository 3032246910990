// @ts-nocheck
import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Badge,
  Box,
  Button,
  Hidden,
  IconButton,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  Tabs,
  Tab,
} from '@mui/material';

import UserAvatar from '../UserAvatar';

import {
  Menu as MenuIcon,
  Warning as WarningIcon,
  MeetingRoom as RoomIcon,
  Home as HomeIcon,
  ExitToApp as LogoutIcon,
  Settings as SettingsIcon,
  Terminal as BridgeIcon,
  Help as HelpIcon,
} from '@mui/icons-material';

import {
  ShieldAccount as AdminIcon,
  AccountCog as SubscriptionIcon,
} from 'mdi-material-ui';

import { ReactComponent as BroadcasterLogo } from '../../illustrations/logo.svg';

import { withStyles } from '@mui/styles';
import { withSubscriptionContext } from '../../contexts/SubscriptionContext';
import { withUserContext } from '../../contexts/UserContext';
import { hasOwnProperty } from '../../lib/utils';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 30,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Badge);

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null,
      },
    };
  }

  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl,
      },
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null,
      },
    });
  };

  render() {
    // Properties

    const { performingAction, user, roles, location, logoURL } = this.props;

    // Events
    const {
      onSettingsClick,
      onSignOutClick,
      onSignUpClick,
      onSignInClick,
      orgSubscription,
    } = this.props;

    const { menu } = this.state;

    const isWebSource = matchPath(location.pathname, {
      path: '/session/websource/:orgId/:roomId/:sourceId',
    });

    const isExternalLink = matchPath(location.pathname, {
      path: '/ext/:orgId/:roomId',
    });

    const userMenuItems = [
      {
        name: 'Admin',
        to: '/admin',
        condition: roles.includes('admin'),
        icon: <AdminIcon />,
      },
      // {
      //   name: "About",
      //   onClick: onAboutClick,
      // },
      // {
      //   name: "Getting Started",
      //   to: '/getting-started'
      // },
      // {
      //   name: "Rooms",
      //   to: '/rooms'
      // },
      // {
      //   name: "Profile",
      //   to: user ? `/user/${user.uid}` : null,
      // },
      {
        name: 'Settings',
        onClick: onSettingsClick,
        icon: <SettingsIcon />,
      },
      {
        name: 'Manage subscription',
        to: '/manage-subscription',
        icon:
          orgSubscription?.status === 'past_due' ? (
            <WarningIcon color='error' />
          ) : (
            <SubscriptionIcon />
          ),
      },
      {
        name: 'Log out',
        divide: true,
        onClick: onSignOutClick,
        icon: <LogoutIcon />,
      },
      {
        name: 'Support',
        to: '/support',
        icon: <HelpIcon />,
      },
    ];

    const isLoggedIn = user && !user.isAnonymous;

    if (isWebSource !== null) {
      return null;
    }

    return (
      <AppBar color='primary' position='sticky' enableColorOnDark>
        <Toolbar>
          <Box
            display='flex'
            flexGrow={1}
            style={{ height: '60px', padding: '5px', minWidth: '100px' }}
          >
            {logoURL ? (
              <img
                src={logoURL}
                alt='Company logo'
                style={{ maxWidth: '100%', height: '100%' }}
              />
            ) : (
              <BroadcasterLogo style={{ width: 100, height: '100%' }} />
            )}
          </Box>

          {!isLoggedIn && !isExternalLink && (
            <>
              <Hidden mdDown>
                <Button color='inherit' component={Link} to='/'>
                  Home
                </Button>
                <Button
                  color='inherit'
                  component={'a'}
                  href='https://updates.broadcastbridge.app'
                >
                  Blog
                </Button>
                {/* <Button component={Link} to="/about">About</Button>*/}
                <Button color='inherit' component={Link} to='/pricing'>
                  Pricing
                </Button>
                <Button color='inherit' onClick={onSignUpClick}>
                  Sign up
                </Button>
                <Button color='inherit' onClick={onSignInClick}>
                  Log in
                </Button>
                {location.pathname.includes('/ext/') && (
                  <Button color='inherit' onClick={onSettingsClick}>
                    Settings
                  </Button>
                )}
              </Hidden>

              <Hidden mdUp>
                <IconButton
                  color='inherit'
                  disabled={performingAction}
                  onClick={this.openMenu}
                  size='large'
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={menu.anchorEl}
                  open={Boolean(menu.anchorEl)}
                  onClose={this.closeMenu}
                >
                  <MenuItem
                    component={Link}
                    to='/'
                    onClick={this.closeMenu}
                    sx={{ width: 170 }}
                  >
                    Home
                  </MenuItem>
                  <MenuItem
                    component={'a'}
                    href='https://updates.broadcastbridge.app'
                  >
                    Blog
                  </MenuItem>
                  {/* <MenuItem component={Link} to="/about">About</MenuItem>*/}
                  <MenuItem component={Link} to='/pricing'>
                    Pricing
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={onSignUpClick}>Sign up</MenuItem>
                  <MenuItem onClick={onSignInClick}>Log in</MenuItem>
                  {location.pathname.includes('/ext/') && (
                    <MenuItem onClick={onSettingsClick}>
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary='Settings' />
                    </MenuItem>
                  )}
                </Menu>
              </Hidden>
            </>
          )}

          {isLoggedIn && (
            <>
              <IconButton
                color='inherit'
                disabled={performingAction}
                onClick={this.openMenu}
                size='large'
              >
                {orgSubscription?.status === 'past_due' ? (
                  <StyledBadge badgeContent='!' color='error'>
                    <UserAvatar />
                  </StyledBadge>
                ) : (
                  <UserAvatar />
                )}
              </IconButton>

              <Menu
                anchorEl={menu.anchorEl}
                open={Boolean(menu.anchorEl)}
                onClose={this.closeMenu}
              >
                {userMenuItems.map((menuItem, index) => {
                  if (
                    hasOwnProperty(menuItem, 'condition') &&
                    !menuItem.condition
                  ) {
                    return null;
                  }

                  let component = null;

                  if (menuItem.to) {
                    component = (
                      <MenuItem
                        key={index}
                        component={
                          menuItem.to.slice(0, 4) === 'http' ? 'a' : Link
                        }
                        to={menuItem.to}
                        href={menuItem.to}
                        onClick={this.closeMenu}
                      >
                        {menuItem.icon ? (
                          <>
                            <ListItemIcon>{menuItem.icon}</ListItemIcon>
                            <ListItemText primary={menuItem.name} />
                          </>
                        ) : (
                          menuItem.name
                        )}
                      </MenuItem>
                    );
                  } else {
                    component = (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          this.closeMenu();

                          menuItem.onClick();
                        }}
                      >
                        {menuItem.icon ? (
                          <>
                            <ListItemIcon>{menuItem.icon}</ListItemIcon>
                            <ListItemText primary={menuItem.name} />
                          </>
                        ) : (
                          menuItem.name
                        )}
                      </MenuItem>
                    );
                  }

                  if (menuItem.divide) {
                    return (
                      <span key={index}>
                        <Divider />

                        {component}
                      </span>
                    );
                  }

                  return component;
                })}
              </Menu>
            </>
          )}
        </Toolbar>
        {orgSubscription &&
          isLoggedIn &&
          user.orgId &&
          orgSubscription.status !== 'past_due' && (
            <Tabs value={false} indicatorColor='secondary'>
              <Tab
                icon={<HomeIcon />}
                label='Home'
                component={Link}
                to='/'
                sx={{ color: 'primary.contrastText' }}
              />
              <Tab
                icon={<BridgeIcon />}
                label='Bridges'
                component={Link}
                to='/bridges'
                sx={{ color: 'primary.contrastText' }}
              />
              <Tab
                icon={<RoomIcon />}
                label='Rooms'
                component={Link}
                to='/rooms'
                sx={{ color: 'primary.contrastText' }}
              />
            </Tabs>
          )}
      </AppBar>
    );
  }
}

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default withRouter(withSubscriptionContext(withUserContext(Bar)));
