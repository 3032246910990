// @ts-nocheck
import { analytics, firestore } from '../firebase';
import {
  arrayRemove,
  collection,
  deleteField,
  doc,
  addDoc,
  updateDoc,
  getDoc,
  getDocs,
  deleteDoc,
  query,
  where,
  writeBatch,
} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';

import { getCurrentUser, getOrgId } from './authentication';

export const updateRoom = async (id, data, key) => {
  if (id === undefined) {
    throw new Error('No room id');
  }

  if (data === undefined) {
    throw new Error(`No ${key}`);
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const roomRef = doc(firestore, `organisations/${userOrgId}/rooms`, id);

  try {
    updateDoc(roomRef, {
      [key]: data,
      watcherProcess: deleteField(),
    });
    logEvent(analytics, `change_room_${key}`);
  } catch (reason) {
    throw reason;
  }
};

export const create = async (fields) => {
  if (!fields) {
    throw new Error('No fields');
  }

  const name = fields.roomName;
  const wssUri = fields.wssUri;
  const videoCodecs = fields.videoCodecs;
  const maxParticipants = fields.maxParticipants;
  const videoSVC = fields.videoSVC;
  const bridge = fields.bridge;
  const systemDisabled = fields.systemDisabled;

  if (!name) {
    throw new Error('No room name');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const orgRoomsCollectionRef = collection(
    firestore,
    `organisations/${userOrgId}/rooms`,
  );

  try {
    const newRoomRef = await addDoc(orgRoomsCollectionRef, {
      name,
      roomPassword: Math.random()
        .toString(36)
        .replace(/[^a-z0-9]+/g, ''),
      roomApiSecret: Math.random()
        .toString(36)
        .replace(/[^a-z0-9]+/g, ''),
      createdByUserId: currentUser.uid,
      wssUri,
      videoCodecs,
      targetResolution: '1280x720',
      maxParticipants,
      videoSVC,
      bridge,
      turnNetwork: 'default',
      forceTurn: false,
      firFrequency: 5,
      systemDisabled,
    });
    logEvent(analytics, 'create_room');

    const newRoom = await getDoc(newRoomRef);
    return { id: newRoom.id, ...newRoom.data() };
  } catch (reason) {
    throw reason;
  }
};

export const addNdiInput = async (id, data) => {
  if (!data.path) {
    throw new Error('No stream input path');
  }

  if (!id) {
    throw new Error('No room id');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  try {
    const userOrgId = await getOrgId();

    const roomWhipCollection = collection(
      firestore,
      `organisations/${userOrgId}/rooms/${id}/whip`,
    );

    const docRef = await addDoc(roomWhipCollection, {
      active: true,
      name: data.name,
      input: data.path,
      requestVideo: !!data?.requestVideo,
      requestAudio: !!data?.requestAudio,
      type: 'ndi',
    });
    logEvent(analytics, 'add_room_ndiInput');

    return docRef;
  } catch (reason) {
    throw reason;
  }
};

export const updateNdiInput = (id, inputId, data) => {
  return new Promise(async (resolve, reject) => {
    if (!inputId) {
      reject(new Error('No stream input ID'));

      return;
    }

    if (!id) {
      reject(new Error('No room id'));

      return;
    }

    if (!data) {
      reject(new Error('No data to update'));

      return;
    }

    const currentUser = await getCurrentUser();

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const userOrgId = await getOrgId();

    const roomWhipInputDoc = doc(
      firestore,
      `organisations/${userOrgId}/rooms/${id}/whip/`,
      inputId,
    );

    updateDoc(roomWhipInputDoc, {
      ...data,
    })
      .then(() => {
        logEvent(analytics, 'update_room_ndiInput');

        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const removeNdiInput = (id, inputId) => {
  return new Promise(async (resolve, reject) => {
    if (!inputId) {
      reject(new Error('No stream input ID'));

      return;
    }

    if (!id) {
      reject(new Error('No room id'));

      return;
    }

    const currentUser = await getCurrentUser();

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const userOrgId = await getOrgId();

    const roomWhipInputDoc = doc(
      firestore,
      `organisations/${userOrgId}/rooms/${id}/whip/`,
      inputId,
    );

    deleteDoc(roomWhipInputDoc)
      .then((value) => {
        logEvent(analytics, 'remove_room_ndiInput');

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const removeAllNdiInputs = (id) => {
  return new Promise(async (resolve, reject) => {
    if (!id) {
      reject(new Error('No room id'));

      return;
    }

    const currentUser = await getCurrentUser();

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const userOrgId = await getOrgId();

    const roomWhipCollection = collection(
      firestore,
      `organisations/${userOrgId}/rooms/${id}/whip/`,
    );

    getDocs(query(roomWhipCollection, where('type', '==', 'ndi')))
      .then((querySnapshot) => {
        const removeSubCollection = [];
        querySnapshot.forEach((input) => {
          removeSubCollection.push(deleteDoc(input.ref));
        });

        Promise.all(removeSubCollection).then(() => {
          logEvent(analytics, 'remove_all_room_ndiInputs');
        });

        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const removeAllWhipInputs = (id) => {
  return new Promise(async (resolve, reject) => {
    if (!id) {
      reject(new Error('No room id'));

      return;
    }

    const currentUser = await getCurrentUser();

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const userOrgId = await getOrgId();

    const roomWhipCollection = collection(
      firestore,
      `organisations/${userOrgId}/rooms/${id}/whip/`,
    );

    getDocs(query(roomWhipCollection, where('type', '==', 'whip')))
      .then((querySnapshot) => {
        const removeSubCollection = [];
        querySnapshot.forEach((input) => {
          removeSubCollection.push(deleteDoc(input.ref));
        });

        Promise.all(removeSubCollection).then(() => {
          logEvent(analytics, 'remove_all_room_whipInputs');
        });

        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const deleteRoom = (id) => {
  return new Promise(async (resolve, reject) => {
    if (!id) {
      reject(new Error('No room id'));

      return;
    }

    const currentUser = await getCurrentUser();

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const userOrgId = await getOrgId();

    const roomRef = doc(firestore, `organisations/${userOrgId}/rooms`, id);

    deleteDoc(roomRef)
      .then((value) => {
        logEvent(analytics, 'delete_room');

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const list = async () => {
  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();

  const orgRoomsCollectionRef = collection(
    firestore,
    `organisations/${userOrgId}/rooms`,
  );

  try {
    const snapshot = await getDocs(orgRoomsCollectionRef);

    const array = [];
    snapshot.forEach((doc) => {
      array.push({ ...doc.data(), id: doc.id });
    });
    logEvent(analytics, 'listed_rooms');

    return array;
  } catch (reason) {
    throw reason;
  }
};

export const createTag = async (tagName, roomId) => {
  if (!tagName || !roomId) {
    throw new Error('No tag name or room ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();
  if (!userOrgId) {
    throw new Error('The user has no organisation ID');
  }

  try {
    const tagsRef = collection(
      firestore,
      `organisations/${userOrgId}/rooms/${roomId}/tags`,
    );

    const tagQuery = await getDocs(
      query(tagsRef, where('name', '==', tagName)),
    );

    if (tagQuery.docs.length) {
      throw new Error('Tag already exists');
    }

    const newTag = await addDoc(tagsRef, { name: tagName });

    return { id: newTag.id, name: tagName };
  } catch (reason) {
    throw reason;
  }
};

export const deleteTag = async (tagId, roomId) => {
  if (!tagId || !roomId) {
    throw new Error('No tag ID or room ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();
  if (!userOrgId) {
    throw new Error('The user has no organisation ID');
  }

  try {
    await deleteDoc(
      doc(firestore, `organisations/${userOrgId}/rooms/${roomId}/tags`, tagId),
    );
  } catch (reason) {
    throw reason;
  }
};

export const removeTagsFromStream = async (tagIdsList, streamId, roomId) => {
  try {
    await removeTagsFromFirestoreDoc(tagIdsList, streamId, roomId, 'streams');
  } catch (reason) {
    throw reason;
  }
};

export const removeTagsFromUser = async (tagIdsList, userId, roomId) => {
  try {
    await removeTagsFromFirestoreDoc(
      tagIdsList,
      userId,
      roomId,
      'participants',
    );
  } catch (reason) {
    throw reason;
  }
};

export const removeUser = async (userId, roomId) => {
  if (!userId || !roomId) {
    throw new Error('No tag ID or room ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();
  if (!userOrgId) {
    throw new Error('The user has no organisation ID');
  }

  try {
    await deleteDoc(
      doc(
        firestore,
        `organisations/${userOrgId}/rooms/${roomId}/participants`,
        userId,
      ),
    );
  } catch (reason) {
    throw reason;
  }
};

export const getAllowAllStreams = async (roomId) => {
  if (!roomId) {
    throw new Error('No room ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();
  if (!userOrgId) {
    throw new Error('The user has no organisation ID');
  }

  try {
    const roomDoc = await getDoc(
      doc(firestore, `organisations/${userOrgId}/rooms`, roomId),
    );

    const allowAllStreams = roomDoc.data().allowAllStreams;

    return allowAllStreams;
  } catch (reason) {
    throw reason;
  }
};

export async function removeTagsFromFirestoreDoc(
  tagIdsList,
  docId,
  roomId,
  collection,
) {
  if (!collection) {
    throw new Error('internal');
  }

  if (!tagIdsList?.length || !docId || !roomId) {
    throw new Error('No tag ID or doc ID or room ID');
  }

  const currentUser = await getCurrentUser();

  if (!currentUser) {
    throw new Error('No current user');
  }

  const userOrgId = await getOrgId();
  if (!userOrgId) {
    throw new Error('The user has no organisation ID');
  }

  const batch = writeBatch(firestore);

  try {
    const docRef = await doc(
      firestore,
      `organisations/${userOrgId}/rooms/${roomId}/${collection}`,
      docId,
    );

    tagIdsList.forEach((tagId) => {
      batch.update(docRef, {
        tags: arrayRemove(tagId),
      });
    });

    await batch.commit();
  } catch (reason) {
    throw reason;
  }
}

export const ndiOutputTag = {
  id: 'ndiOutput',
  name: 'NDI Output',
};
